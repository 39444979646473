import React, {useEffect, useState}                                                     from 'react';
import {Button, Tooltip, OverlayTrigger, Offcanvas}                                     from 'react-bootstrap';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import ShareTextComponent                                                               from "./shareTextComponent";
import {Helmet}                                                                         from 'react-helmet';
import Utils                                                                            from "../../common/utils";
import UiConfig                                                                         from "../../common/ui-config";
import ReactGA                                                                          from "react-ga4";

type ShareProps = {
    statement: string;
    sharedTab?: string,
    correlations: any,
    regressionResult: any,
    r2?: any,
}
const ShareBlockOffCanvasComponent: React.FC<ShareProps> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const [copiedText, setCopiedText] = useState<string | null>(null);
    const [mainText, setMainText] = useState<string>("");
    const [shareUrl, setShareUrl] = useState<string>("");
    const [titleM, setTitleM] = useState<string>("");
    const sharedTab = props.sharedTab || '';
    const correlations = props.correlations;
    const regressionResult = props.regressionResult || [];

    const handleClose = () => {
        setShow(false);
        setCopiedText(null);
    };

    const handleShow = () => {
        ReactGA.event({category: 'Share', action: 'Clicked Share button'});
        setShow(true);
    };

    useEffect(() => {
        const {titleM, shareText} = UiConfig.shareTextDetails[sharedTab] || UiConfig.shareTextDetails['default'];
        setTitleM(titleM);

        let urlObject = new URL(window.location.href);
        if (props.sharedTab) {
            let params = new URLSearchParams();
            params.append('tab', props.sharedTab);
            urlObject.search = params.toString();
        }

        const shareUrlLocal = urlObject.toString();
        setShareUrl(shareUrlLocal);

        let stmt = JSON.stringify(props.statement);
        let tempText = shareText(stmt);

        if (correlations && correlations.length > 0) {
            tempText += '\n\n' + correlations.map((correlation: any) => {
                let formattedCorrelations = '';

                if (correlation.actualCorrelations && correlation.actualCorrelations.length > 0) {
                    formattedCorrelations = `, from studies ${correlation.actualCorrelations[0].corr_data.map(
                        (ac: any) => {
                            const formattedCorrelation = Utils.formatCorrelation(ac.correlation);
                            return isNaN(Number(formattedCorrelation)) || formattedCorrelation === '' ? ''
                                                                                                      : formattedCorrelation;
                        }).filter((text: string) => text).join(', ')}`;
                }

                const predictedCorrelation = Utils.formatCorrelation(correlation.correlation);

                return ` • "${correlation.text}": predicted ${predictedCorrelation}${formattedCorrelations}\n`;
            }).filter((text: string) => text.trim() !== '').join('');
        }

        if (regressionResult && regressionResult.length > 0) {
            tempText += '\n\n' + regressionResult.map((res: any) => `\"${res.text}\"  ${res.corr_num} `).join('\n');
            if (props.r2) {
                let r2New = Utils.formatR2(props.r2);
                tempText += `\n\nModel R^2: ${r2New}`;
            }
        }
        tempText += `\n\n${shareUrlLocal}`;
        setMainText(tempText);
    }, [sharedTab, props.statement, correlations, regressionResult, props.r2]);

    const handleCopy = async (event: any) => {
        try {
            await navigator.clipboard.writeText(mainText);
            setCopiedText(mainText);
        }
        catch (err) {
            console.error('Error occurred during copy: ', err);
        }
    };

    useEffect(() => {
        if (copiedText) {
            // Handle any side effects when text is copied
        }
    }, [copiedText]);

    return (
        <>
            <Button variant="secondary" onClick={handleShow} className={"shareBtn"}>
                <span className="share-text pe-1">Share</span>
                <i className="bi bi-box-arrow-in-up-right "></i>
            </Button>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <h5 className="p-3 m-3 pb-0">Ways to share this result:</h5>
                    <div className="social d-flex flex-column align-content-center p-3 m-3 mt-0">
                        <button className='btn btn-outline-primary m-2 ms-4 p-2 share-button'>
                            <span className="pe-2" onClick={handleCopy}>Copy data and link</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 className="bi bi-copy" viewBox="0 0 16 16">
                                <path
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                            </svg>
                        </button>
                        <EmailShareButton
                            className='m-2 ms-4 d-block'
                            subject={titleM}
                            body={mainText}
                            url={''}>
                            <div className='btn btn-outline-primary p-2 share-button'>
                                <span className="pe-2">Send an email</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     className="bi bi-envelope-at" viewBox="0 0 16 16">
                                    <path
                                        d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
                                    <path
                                        d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                                </svg>
                            </div>
                        </EmailShareButton>
                        <LinkedinShareButton
                            url={shareUrl}
                            className='m-2 ms-4 d-block'
                        >
                            <div className='btn btn-outline-primary p-2 share-button'>
                                <span className="pe-2">Post on LinkedIn</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     className="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path
                                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </div>
                        </LinkedinShareButton>
                        <TwitterShareButton
                            url={shareUrl}
                            title={mainText}
                            hashtags={['personalityMap']}
                            className='m-2 ms-4 d-block'>
                            <div className='btn btn-outline-primary p-2 share-button'>
                                <span className="pe-2">Post on X (Twitter)</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                     className="bi bi-twitter-x fs-3" viewBox="0 0 16 16">
                                    <path
                                        d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                            </div>
                        </TwitterShareButton>
                    </div>
                    {copiedText && (
                        <div className="p-3 pt-5 m-3">
                            <div className="pb-3 lead">Copied to the clipboard:</div>
                            <ShareTextComponent stmt={props.statement} shareUrl={shareUrl}
                                                correlations={correlations} regressionResult={regressionResult}
                                                modelR2={props.r2} copiedText={copiedText} mainText={mainText}
                            />
                        </div>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
export default ShareBlockOffCanvasComponent;