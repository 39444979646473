import React, {useEffect, useRef, useState}                                from "react";
import QuestionType                                                        from "../types/question.type";
import {useQuery}                                                          from "react-query";
import QuestionDataService                                                 from "../services/question.service";
import {useNavigate, useSearchParams}                                      from "react-router-dom";
import UiConfig                                                            from "../common/ui-config";
import useSessionStorage                                                   from "../common/sessionStorage";
import useWindowDimensions                                                 from "../common/useWindowDimentions";
import {LoadingGif, LoadingSimple, Surprise}                               from "./UIElements";
import MostCorrelated
                                                                           from "./correlationsComponents/most-correlated.component";
import AppSettings                                                         from "../common/app-settings";
import ShareBlockOffCanvasComponent
                                                                           from "./shareComponent/shareBlockOffCanvasComponent";
import {Footer}                                                            from "./authAndNav/footer.component";
import {useStudyDetails}                                                   from "../requestHooks";
import StudyInfoComponent                                                  from "./stydyDetails/studyInfo.component";
import InputBlockComponent                                                 from "./inputBlock/inputBlock.component";
import {useUser}                                                           from "../context/UserProvider/UserProvider";
import {useSearch}                                                         from "../requestHooks/useSearch";
import {useExamples}                                                       from "../requestHooks/useExamples";
import useGA                                                               from "../common/useGA4";
import {readFromLocalStorage, removeFromLocalStorage, writeToLocalStorage} from "../common/localStorageUtils";
import {OriginalSearch}                                                    from "../types/localStorageVars";
import {
    useLocationContext
}                                                                          from "../context/LocationProvider/LocationProvider";

const UI_STRINGS = require('../common/ui-strings.json')
//TODO: USE SVG
const qInDbIcon = require('../assets/icons/noun-in-stock-3085953.png');
const qNotInDbIcon = require('../assets/icons/noun-out-of-stock-3085957.png');

const maintenanceMode: boolean = AppSettings.maintenanceMode;


const QuestionSearch = () => {
    const {authenticatedUser, requireAuth, accountParams} = useUser();
    const navigate = useNavigate();
    const org_id = "main";
    const SCORE_BONUS_TEXTUAL = 0.30;

    const {height, width} = useWindowDimensions();
    let STUDIES_CORR_NUM = 3;
    if (width < 1200) {
        STUDIES_CORR_NUM = 2;
    }
    const {sendGAEvent} = useGA();
    const originalSearch = useRef<OriginalSearch | null>(
        readFromLocalStorage("originalSearchData") || null
    );
    const [apiDown, setApiDown] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let queryParamSearchStrString = searchParams.get("search") || '';
    let queryParamSearchStr = decodeURIComponent(queryParamSearchStrString);
    const debugString = searchParams.get("debug") || '';
    const debugMode = !!decodeURIComponent(debugString);

    const {updateMetaStatement} = useLocationContext();

    const [sessionSearchResult, setSessionSearchResult] = useSessionStorage('result', null);
    const [sessionSearchQuery, setSessionSearchQuery] = useSessionStorage('search', null);
    const [qsForSearchExamples, setQsForSearchExamples] = useState<QuestionType[]>([]);
    const [searchString, setSearchString] = useState("");
    const [getSearchResult, setGetSearchResult] = useState<Array<QuestionType> | null>(null);
    const {
              goDoTheSearch,
              isLoadingSearchResults
          } = useSearch(searchString, org_id, debugMode, setGetSearchResult, handleErrors, handleSearchResult);
    const {isLoadingExamples, getRandomExamples} = useExamples(org_id, handleSearchExamples, handleErrors);
    const [actualForSearchResult, setActualForSearchResult] = useState<any[] | null>(null);
    const {
              studyInfo,
              isLoadingStudyDetails,
              requestedIndex,
              correlationDetails,
              showStudyDetails,
              handleClose,
              handleShowStudyDetails, correlatedSQtoDisplay
          } = useStudyDetails([]);
    const [displayResultsState, setDisplayResultsState] = useState<boolean>(false);
    const [mainQLink, setMainQLink] = useState<QuestionType | null>(null);
    const [userTextAsQ, setUserTextAsQ] = useState<string | null>(null);

    // 0 - blocked until auth, 1 - trial, 2 - full access
    const [accessLevel, setAccessLevel] = useState<number | undefined>(undefined);

    const initializeLockRequests = () => {
        return accessLevel === 0;
    };

    const [lockRequests, setLockRequests] = useState<boolean>(initializeLockRequests);

    // Ensure the component re-renders if accessLevel changes
    React.useEffect(() => {
        setLockRequests(initializeLockRequests());
    }, [accessLevel]);

    const [counter, setCounter] = useSessionStorage("counter", null);
    const [counterExample, setCounterExample] = useSessionStorage("counterExample", null);

    const formatResponse = (res: any) => {
        return JSON.stringify(res, null, 2);
    };

    const countUsage = (param?: string) => {
        if (param && param === 'randomizer') {
            let n = getUsageExample();
            setCounterExample(String(n + 1));
        }
        else {
            let n = getUsage();
            setCounter(String(n + 1));
        }
    }

    function goGetActualCorr() {
        if (displayResultsState) {
            requestActualForSearchResult();
        }
    }

    function initialize() {
        if (originalSearch.current && originalSearch.current["originalSearchString"]
            && originalSearch.current["originalSearchResults"])
        {
            let originalSearchString = originalSearch.current["originalSearchString"];
            let originalSearchResults = originalSearch.current["originalSearchResults"];
            let originalSearchExamples = originalSearch.current["originalSearchExamples"];
            let originalActualForSearch = originalSearch.current["originalActualForSearch"];

            originalSearch.current = null; // Clearing the ref's current value
            removeFromLocalStorage("originalSearchData");
            setSearchString(originalSearchString);
            setGetSearchResult(originalSearchResults);
            if (originalSearchExamples) setQsForSearchExamples(originalSearchExamples);
            if (originalActualForSearch) setActualForSearchResult(originalActualForSearch);

            let input: any = document.getElementById("mainSearchInput");
            input.value = originalSearchString;

        }
        else {
            getRandomExamples(); // Handle random examples case
            if (queryParamSearchStr && queryParamSearchStr.trim().length > 0) {
                setSearchString(queryParamSearchStr);

                if (queryParamSearchStr === sessionSearchQuery) {
                    setGetSearchResult(sessionSearchResult);
                }
            }
        }
    }


    function randomizeExamplesClicked() {
        sendGAEvent('Question Search Page', 'Clicked Randomize Examples', 'Clicked Randomize Examples')

        if (accessLevel !== undefined && accessLevel === 0) {
            requireAuth();
        }
        else {
            if (accessLevel !== undefined && accessLevel === 1) {
                countUsage("randomizer");
            }
            setQsForSearchExamples([]);
            getRandomExamples();
        }

    }

    function qTypeToInclude(q: QuestionType) {
        return UiConfig.includeTypes.includes(q.question_type)
    }

    async function handleSearchResult(res: any) {
        if (res !== undefined) {
            let t: QuestionType[] = res.textual_search_result;
            let c: QuestionType[] = res.conceptual_search_result
            // remove questions with undesired types
            let tFiltered = t.filter(qTypeToInclude);
            let cFiltered = c.filter(qTypeToInclude);

            // check if direct match question exists

            let t_ids = [];
            for (let i = 0; i < tFiltered.length; i++) {

                let corr_dict: any;
                (
                    tFiltered[i].score
                ) ? corr_dict = tFiltered[i].score : corr_dict = {};
                if (corr_dict) tFiltered[i].sort_score = Math.abs(corr_dict["correlation"]) + SCORE_BONUS_TEXTUAL;

                tFiltered[i].result_type = "t";

                t_ids.push(tFiltered[i].universal_q_id);
            }

            for (let i = 0; i < cFiltered.length; i++) {
                //remove duplicates between conceptual and textual search results
                let id = cFiltered[i].universal_q_id;
                let ind = t_ids.indexOf(id);
                if (ind > -1) {
                    cFiltered.splice(i, 1);
                    i--;
                }
                else {
                    let corr_dict: any;
                    if (cFiltered[i].score !== undefined) {
                        corr_dict = cFiltered[i].score;
                    }
                    if (corr_dict) cFiltered[i].sort_score = Math.abs(corr_dict["correlation"]);
                    cFiltered[i].result_type = "c";
                }
            }

            let result = [...tFiltered, ...cFiltered];

            result.sort(function (a, b) {
                if (b.sort_score && a.sort_score) {
                    return Math.abs(b.sort_score) - Math.abs(a.sort_score)
                }
                else {
                    return 0
                }
            });
            setGetSearchResult(result);
            setDisplayResultsState(true);
            setSessionSearchResult(result);
            setSessionSearchQuery(searchString);
        }
    }

    function handleSearchExamples(res: any) {
        if (res) {
            setQsForSearchExamples(res);
        }

    }

    function handleActualForSearchResult(res: any) {
        if (res) {
            if (res["for"] == searchString) {
                let filtered = res["response"].filter((r: any[]) => r.length > 0);

                if (filtered.length && getSearchResult) {
                    let actual = res["response"].map((r: any) => {
                        if (r.length > 0) {
                            let r0 = r[0];
                            return {
                                "text"     : r0.question_text_1 === mainQLink?.canonical_text ? r0.question_text_2
                                                                                              : r0.question_text_1,
                                "corr_data": r.map((s: any) => (
                                    {
                                        correlation: s.correlation,
                                        study_id   : s.study_id,
                                        sample_size: s.sample_size
                                    }
                                )).sort((a: any, b: any) => b.sample_size - a.sample_size).slice(0, STUDIES_CORR_NUM)
                            }
                        }
                        else {
                            return null
                        }
                    })
                    let actualMCResults = [];
                    let displayingResults = getSearchResult.filter(r => r.canonical_text !== mainQLink?.canonical_text)
                    for (let m of displayingResults) {
                        let current = actual.filter((a: any) => (
                            a && a.text && a.text === m.canonical_text
                        ))[0]
                        actualMCResults.push(current)
                    }
                    setActualForSearchResult(actualMCResults);
                }
                else {
                    setActualForSearchResult([]);
                }
            }
            else {
                goGetActualCorr();
            }

        }

    }


    function modifyQueryParam() {
        if (searchString) {
            let addParam = '';
            debugMode ? addParam = '&debug=1' : addParam = '';
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?search='
                         + searchString + addParam;
            window.history.pushState({path: newurl}, '', newurl);
        }

        if (searchString.length > 0) {
            let dValue = '1';
            let searchParams;
            debugMode ? searchParams = {search: searchString, debug: dValue} : searchParams = {search: searchString,}
            setSearchParams(searchParams, {replace: true},)
        }
    }

    function findMainQLinkMatch() {
        if (getSearchResult && getSearchResult.length > 0) {
            let mainQLinkArr = getSearchResult.filter((q) => {
                                                          return q.canonical_text === searchString
                                                      }
            )
            if (mainQLinkArr.length > 0) {
                setUserTextAsQ(null);
                setMainQLink(mainQLinkArr[0]);
                // let index = tFiltered.indexOf(mainQLinkArr[0]);
                // result.splice(index, 1);
            }
            else {
                setMainQLink(mainQLinkArr[0]);
                setUserTextAsQ(searchString);
            }
        }
    }

    const {
              isFetching: isLoadingActualCorr,
              refetch   : requestActualForSearchResult
          } = useQuery<any[], Error>(
        "query-actual-corr-search-result",
        async () => {
            if (getSearchResult && getSearchResult.length > 0 && mainQLink) {
                if (mainQLink?.universal_q_id) {
                    const mostCorrTexts = getSearchResult.filter(q => q.canonical_text !== mainQLink.canonical_text)
                                                         .map(e => e.canonical_text);
                    return await QuestionDataService.getStudyCorrelationsForTexts(
                        org_id, mainQLink.canonical_text, mostCorrTexts);
                }
            }
        },
        {
            enabled  : false,
            retry    : 5,
            onSuccess: (res) => handleActualForSearchResult(res),
            onError  : (err: any) => {
                handleErrors('glitch', err);
            },
        }
    )


    function handleErrors(errType: string, err: any) {
        setApiDown(true);
        setLockRequests(true);


    }


    function questionSelected(q: QuestionType) {
        sendGAEvent('Navigation', 'Navigating to a question from search results', q.canonical_text)
        writeToLocalStorage('questionData', {
            question   : q,
            custom_text: null,
            sharedTab  : null,
        })
        writeToLocalStorage("originalSearchData", {
            originalSearchString   : searchString,
            originalSearchResults  : getSearchResult,
            originalSearchExamples : qsForSearchExamples,
            originalActualForSearch: actualForSearchResult,
        })
        navigate("/questions/" + q.universal_q_id, {
            replace: false,
        });
    }


    function userTextAsQSelected() {
        sendGAEvent(
            'Navigation', 'Navigating to textAsQuestion from search results, question not in DB',
            encodeURIComponent(searchString)
        );
        writeToLocalStorage('questionData', {
            question   : null,
            custom_text: searchString,
        })
        writeToLocalStorage("originalSearchData", {
            originalSearchString   : searchString,
            originalSearchResults  : getSearchResult,
            originalSearchExamples : qsForSearchExamples,
            originalActualForSearch: actualForSearchResult,
        })
        navigate("/questions/ut?text=" + encodeURIComponent(searchString), {
            replace: false,
        });
    }


    function getUsage() {
        let n = counter;
        if (n) {
            return parseInt(n);
        }
        else {
            setCounter(0);
            return 0;
        }
    }

    function getUsageExample() {
        let n = counterExample;
        if (n) {
            return parseInt(n);
        }
        else {
            setCounterExample(0);
            return 0;
        }
    }

    const conditionForSearch = () => {
        return (
            accessLevel && accessLevel > 0
        )
    }


    function triggerSearch() {
        if (conditionForSearch()) {
            clearOutput();
            goDoTheSearch();
            setDisplayResultsState(false);
        }
        else {
            requireAuth();
        }
    }


    const clearOutput = () => {
        let notification = document.getElementById("inputNotification");
        if (notification) notification.innerText = "";
        setDisplayResultsState(false);
        setSessionSearchResult(null);
        setMainQLink(null);
        setActualForSearchResult(null);
        setGetSearchResult(null);
    }


    useEffect(() => {
        if (isLoadingSearchResults) {
            setLockRequests(true);
        }
        else if (accessLevel !== 0) {
            setLockRequests(false);
        }

    }, [isLoadingSearchResults]);


    useEffect(() => {
        if (searchString && searchString.length > 0) {
            modifyQueryParam();
        }

    }, [getSearchResult]);


    useEffect(() => {

        const searchRegExp = /((.*)[a-zA-Z0-9](.*)){2,}/;
        // const searchRegExp = new RegExp("/((.*)[a-zA-Z0-9](.*)){" + charLimit + ",}/");
        if (searchString && searchRegExp.test(searchString) && !lockRequests) {
            // check we are not returning to search from the question component
            if (!originalSearch.current) {
                if (accessLevel == 1) countUsage();
                triggerSearch();
            }
        }
        else if (searchString && searchString.length > 0 && !searchRegExp.test(searchString)) {
            let notification = document.getElementById("inputNotification");
            if (notification) notification.innerText = "Please type at least two letters";
        }

    }, [searchString, accountParams]);


    useEffect(() => {
        if (getSearchResult && getSearchResult.length) {
            // setActualForSearchResult(null);
            findMainQLinkMatch();
        }
    }, [getSearchResult]);


    useEffect(() => {
        if (mainQLink && mainQLink.universal_q_id && displayResultsState && actualForSearchResult === null) {
            goGetActualCorr();
        }
    }, [mainQLink]);


    useEffect(() => {
        if (getSearchResult && getSearchResult.length) {
            setDisplayResultsState(true);
        }
    }, [getSearchResult, mainQLink]);


    useEffect(() => {
        if (searchString !== queryParamSearchStr && queryParamSearchStr) {
            setSearchString(queryParamSearchStr);
            clearOutput();
        }
    }, [queryParamSearchStr]);


    useEffect(() => {
        if (accessLevel == 0) {
            setLockRequests(true);
            requireAuth();

        }
        if (accessLevel == 2) {
            setLockRequests(false);
            triggerSearch();
        }

    }, [accessLevel]);


    useEffect(() => {
        if (authenticatedUser) {
            setAccessLevel(2);
            setCounter(0);
            setCounterExample(0);
        }
        else {
            let c = getUsage();
            let e = getUsageExample();
            if (c <= UiConfig.trialAccessLimit && e < UiConfig.trialAccessLimit) {
                setAccessLevel(1);
            }
            else {
                setAccessLevel(0);
            }
        }

    }, [authenticatedUser, counter, counterExample]);

    useEffect(() => {
        updateMetaStatement(searchString)
    }, [searchString])

    useEffect(() => {
        initialize();
        return () => {
            clearOutput();
        }

    }, []);

    return (
        <div className="QuestionSearchContainer container-fluid pt-5 pb-5 d-flex flex-column">
            <main className="flex-grow-1">
                {debugMode && (
                    <div className='debugNotice'>Debug Mode</div>
                )}
                {!maintenanceMode && (
                    <div className="row">
                        <div className="">
                            <label className="pt-3 pb-0 display-6 d-block">{UI_STRINGS.labels.search_prompt}</label>


                            <div className="regular-input-block">
                                <div id="inputNotification" className="ps-2 text-danger"></div>
                                <InputBlockComponent type={'mainSearch'} setFunction={setSearchString} org_id={org_id}
                                                     excludeExamples={[]}
                                                     forbiddenInputStrings={[searchString]}
                                                     isLoadingResults={isLoadingSearchResults}
                                                     questionsForExamples={() => qsForSearchExamples}
                                                     isLoadingExamples={isLoadingExamples} custom_text={''}
                                                     lockRequests={lockRequests}
                                                     randomizeExamplesClicked={randomizeExamplesClicked}/>

                            </div>


                        </div>
                        <div className="row">
                            {isLoadingSearchResults && (
                                <LoadingGif/>

                            )}
                            {apiDown && (
                                <div className="col col-lg-6">
                                    <div className="alert alert-danger">
                                        {UI_STRINGS.notifications.apiDown}
                                    </div>
                                </div>


                            )}

                        </div>


                        {(
                         !isLoadingSearchResults && getSearchResult && displayResultsState
                         ) && (
                             <div className="searchResultsDiv pb-5">

                                 <div className="d-flex">

                                     <div className="resultsFound pt-3 w-100 ">
                                         <div className="d-flex justify-content-end "
                                         >
                                             <ShareBlockOffCanvasComponent statement={searchString} correlations={[]}
                                                                           regressionResult={[]}/>
                                         </div>
                                         {mainQLink && (
                                             <div className="lead fw-normal fs-2 lh-sm   d-inline-block align-bottom"
                                                  onClick={() => questionSelected(mainQLink)}>
                                                 <table className="align-top questionResult">
                                                     <thead></thead>
                                                     <tbody>
                                                     <tr className="align-top">
                                                         <td className=""><img src={qInDbIcon} alt=''
                                                                               width='50px'
                                                                               height='auto'
                                                                               title='We have a question with this exact wording in our database.'/>

                                                         </td>
                                                         <td>
                                                             <div className="ps-1">
                                                                 <div
                                                                     className='text-decoration-underline pe-5'>{searchString}</div>
                                                                 {debugMode && (
                                                                     <span className='mainLinkDebug'><br/>

                                                In&nbsp;training:&nbsp;<span
                                                                             className='ps-2'>{mainQLink.in_training
                                                                                               ? "1" : "0"}<br/></span>

                                            </span>
                                                                 )}
                                                                 {debugMode && (
                                                                     <span className='d-flex flex-wrap'>
                                                {mainQLink.studies?.map((s, index) => (
                                                    <span
                                                        className="mainLinkDebug pe-3" key={index}>{s.study_name}</span>
                                                ))}
                                            </span>
                                                                 )}
                                                             </div>
                                                         </td>
                                                     </tr>
                                                     </tbody>

                                                 </table>
                                             </div>


                                         )}
                                         {userTextAsQ && (
                                             <h2 className="lead fw-normal fs-2 lh-sm questionResult text-decoration-underline d-inline-block"
                                                 onClick={() => userTextAsQSelected()}><img src={qNotInDbIcon} alt=''
                                                                                            width='50px'
                                                                                            height='auto'
                                                                                            className='me-2'
                                                                                            title='We do not have a question with this exact wording in our database, so results will be less accurate.'/>{searchString}<br/>
                                             </h2>
                                         )}
                                         <MostCorrelated custom_text={userTextAsQ}
                                                         mainQLink={mainQLink ? mainQLink : {
                                                             "canonical_text": searchString,
                                                             "universal_q_id": "",
                                                             "question_type" : "User-typed custom text",
                                                             "org_id"        : "main"
                                                         }}
                                                         isLoadingMostCorrelated={isLoadingSearchResults}
                                                         isLoadingStudyCorrelations={isLoadingActualCorr}
                                                         mostCorrelatedResult={getSearchResult}
                                                         questionSelected={questionSelected}
                                                         actualCorrelations={actualForSearchResult}
                                                         debugMode={debugMode}
                                                         handleShowStudyDetails={handleShowStudyDetails}
                                                         accessLevel={accessLevel}/>

                                     </div>

                                 </div>

                             </div>
                         )}


                    </div>

                )}

                {maintenanceMode && (
                    <div className="alert alert-danger fs-4 mt-5">{UI_STRINGS.notifications.maintenanceMode}</div>
                )}
            </main>
            <StudyInfoComponent data={studyInfo}
                                requestedIndex={requestedIndex}
                                isLoading={isLoadingStudyDetails}
                                show={showStudyDetails} handleClose={handleClose}
                                correlationDetails={correlationDetails} correlatedSQtoDisplay={correlatedSQtoDisplay}/>
            <Footer/>
        </div>
    )
}

export default QuestionSearch;
