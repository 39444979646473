// todo: automate this!
// todo: IMPORTANT! Update warmer on the EventBridge before pointing to a new API deployment!
const useDevBackEnd = false; //url in api-client.ts
const maintenanceMode = false;
const whiteListStaticUrls = ['terms_of_service', 'privacy_policy', 'about']
const GAId = "G-7LJCX9FNGM";
const GTMId = "GT-5M87M2P";
const teamAccounts = [
    'n@tnalgo.com', 'natkirpa@gmail.com', 'spencer.g.greenberg@gmail.com', 'a@tnalgo.com',
    'nathan.sparkwave+pmteam1@gmail.com', 'gustavo.lacerda@gmail.com', 'emmanuelnnaemeka847@gmail.com'
];

const debugHostnames = ['localhost', '127.0.0.1', '0.0.0.0', 'dev.personalitymap'];

const AppSettings = {
    useDevBackEnd,
    maintenanceMode,
    whiteListStaticUrls,
    GAId,
    GTMId,
    teamAccounts,
    debugHostnames
}


export default AppSettings;
