import React, {useEffect, useReducer, useRef, useState} from "react";
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    XAxis,
    YAxis
}                                                       from "recharts";
import UiConfig                                         from "../../common/ui-config";
import StudyQuestionType                                from "../../types/study-question.type";
import QuestionStatisticsType                           from "../../types/question-statistics.type";
import {isNaN}                                          from "formik";
import {gamma, index, random, re}                       from "mathjs";
import Utils                                            from "../../common/utils";
import utils                                            from "../../common/utils";
import useWindowDimensions                              from "../../common/useWindowDimentions";
import {Form}                                           from "react-bootstrap";

type Props = {
    qStatistics: QuestionStatisticsType | null,
    qStudyQuestions: StudyQuestionType[] | null,
    dataType: string,
    questionText: string,
};
const UI_STRINGS = require('../../common/ui-strings.json')

const StatsChart = (props: Props) => {
    // @ts-ignore
    const [ignored, forceUpdate] = useReducer((x: number) => x + 1, 0);
    const [minX, setMinX] = useState<number>(0);
    const [maxX, setMaxX] = useState<number | undefined>(undefined);
    const [minY, setMinY] = useState<number>(0);
    const [maxY, setMaxY] = useState<number>(1);
    const [chartData, setChartData] = useState<any[] | undefined>(undefined)
    const [activeStudy, setActiveStudy] = useState<{ studyNum: number, ticks: number[] } | null>(null)
    const [barWidth, setBarWidth] = useState<number | null>(null);
    // @ts-ignore
    const chartRef = useRef<HTMLDivElement>(null);
    const [refSize, setRefSize] = useState([0, 0]);
    const qStudyQuestions = props.qStudyQuestions;
    const [showPrediction, setShowPrediction] = useState(!(
        qStudyQuestions && qStudyQuestions.length > 0
    ));

    function updateSize() {
        if (chartRef.current) {
            let w = chartRef.current.getBoundingClientRect().width;
            let h = chartRef.current.getBoundingClientRect().height;
            setRefSize([w, h]);
        }
    }

    function calcMinMaxX() {
        // find min ane max on the x-axis
        const qStatistics = props.qStatistics;
        let minX = 0;
        let maxX = 1;
        if (qStatistics && qStatistics.min !== undefined && qStatistics.max !== undefined) {
            minX = Number(qStatistics.min);
            maxX = Number(qStatistics.max);
        }
        else if (qStudyQuestions && qStudyQuestions.length > 0) {
            minX = Math.min(...qStudyQuestions.map((sq) => Number(sq.min_value)));
            maxX = Math.max(...qStudyQuestions.map((sq) => Number(sq.max_value)));
        }
        else {
            console.error("No data available for minX and maxX calculation.");
        }
        if (props.dataType === 'non-negative number') {
            if (qStudyQuestions && qStudyQuestions.length > 0) {
                minX = Math.min(...qStudyQuestions.map((sq) => Number(sq.min_value)));
                maxX = Math.max(...qStudyQuestions.map((sq) => Number(sq.max_value)));

            }

        }
        let n = Utils.decimalsNum(minX, maxX);
        setMinX(Number(minX.toFixed(n)));
        setMaxX(Number(maxX.toFixed(n)));
    }

    function transformDataX(x: number, minV: number, maxV: number) {
        if (props.dataType && props.dataType === 'non-negative number') {
            return x * (
                maxV - minV
            ) + minV;
        }
        else {
            return x;
        }
    }

    const calcChartData = () => {
        if (maxX) {

            let data: any[] = [];

            function gammaF(z: number) {
                return gamma(z);
            }

            function normF(alpha: number, beta: number) {
                return gammaF(alpha) * gammaF(beta) / gammaF(alpha + beta);
            }

            function betaDF(x: number, alpha: number, beta: number) {
                return Math.pow(x, alpha - 1) * Math.pow(1 - x, beta - 1) / normF(alpha, beta);
            }

            console.log(props.qStatistics)
            if (props.qStatistics) {
                const qStatistics = props.qStatistics;
                if (qStatistics.beta_distribution_alpha && qStatistics.beta_distribution_beta && props.dataType
                    !== 'yes or no')
                {
                    console.log('THIS SHOULD RUN!')
                    const alpha = qStatistics.beta_distribution_alpha;
                    const beta = qStatistics.beta_distribution_beta;
                    let step = (
                                   qStatistics.max - qStatistics.min
                               ) / UiConfig.chartPrecision;
                    let epsilon = 0.01;
                    for (let x = qStatistics.min; x < qStatistics.max; x += step) {
                        let y = betaDF(x, alpha, beta);
                        let el = {
                            'x': transformDataX(x, minX, maxX),
                            'y': y,
                        };
                        if (el.y !== Number.POSITIVE_INFINITY && el.y !== Number.NEGATIVE_INFINITY && el.x > minX
                            + epsilon && el.x < maxX - epsilon)
                        {
                            data.push(el);
                        }
                    }
                    let maxY = Math.max(...data.map((d) => d.y));
                    console.log("DATA DEBUG: ", data)
                    //normalization for y axis
                    let yMode: number = 0;
                    if (alpha > 1 && beta > 1) {
                        let mode = (
                                       alpha - 1
                                   ) / (
                                       alpha + beta - 2
                                   );
                        yMode = betaDF(mode, alpha, beta);
                    }
                    else if (alpha == 1 && beta == 1) {
                        yMode = 0.9;
                    }
                    else if (alpha < 1 && beta < 1) {
                        yMode = 1;
                    }
                    else if (alpha <= 1 && beta > 1) {
                        yMode = betaDF(epsilon, alpha, beta);
                    }
                    else if (alpha > 1 && beta <= 1) yMode = betaDF(1 - epsilon, alpha, beta);
                    if (qStudyQuestions && qStudyQuestions.length > 0) {
                        qStudyQuestions.map((sq: StudyQuestionType, index: number) => {
                            let keys = sq.frequency_keys;
                            let values = sq.frequency_values;
                            let maxV = Number(sq.max_value);
                            let minV = Number(sq.min_value);
                            let max_value = 1;
                            if (values) {
                                max_value = Math.max(...values.map((v) => Number(v)));
                            }

                            let datakey: string = 'study' + (
                                                  index + 1
                            );
                            if (keys && values && keys.length > 0) {
                                let normSum: number;

                                // normSum wil be used to rescale study data bars height, so it's normalized to 1
                                // @ts-ignore
                                normSum = values?.reduce((partialSum, a, currentIndex) => partialSum + (
                                    (
                                        currentIndex == 0 || (
                                            values !== undefined && currentIndex == values.length - 1
                                        )
                                    ) ? Number(a) / 2 : Number(a)
                                ), 0);
                                let w = 1 / (
                                    keys.length - 1
                                );
                                let normK = 1 / (
                                    w * normSum
                                );

                                for (let i = 0; i < keys?.length; i++) {
                                    let obj: { [key: string]: number } = {};
                                    if (!isNaN(Number(values[i])) && !isNaN(Number(keys[i]))) {
                                        (
                                            i == 0 || i == keys.length - 1
                                        ) ? normSum += Number(values[i]) / 2 : normSum += Number(values[i]);
                                        //    normalizing bar heights
                                        obj[datakey] = Number(values[i]) * normK;
                                        let x = Number(keys[i]);
                                        obj['x'] = transformDataX(x, minV, maxV);
                                        data.push(obj);
                                    }
                                }
                                let maxStudyD = Math.max(...data.map((d) => d[datakey]));
                                maxStudyD > maxY ? maxY = maxStudyD : maxY = maxY;
                                //    this is only to fix a bug with recharts (which only happens for the case of a
                                // single study)
                                if (qStudyQuestions && qStudyQuestions.length == 1) {
                                    let obj = {'study2': -1, x: 1}
                                    data.push(obj);
                                }
                            }

                        })

                    }
                }
                else {
                    if (props.dataType == 'yes or no') {
                        let step = (
                                       qStatistics.max - qStatistics.min
                                   ) / UiConfig.chartPrecision;
                        for (let x = 0; x < 0.5; x += step) {
                            let y = 1 - qStatistics.mean;
                            let el = {
                                'x': x,
                                'y': y,
                            };
                            data.push(el);
                        }
                        for (let x = 0.5; x < 1; x += step) {
                            let y = qStatistics.mean;
                            let el = {
                                'x': x,
                                'y': y,
                            };
                            data.push(el);
                        }
                        const maxY = 1;
                    }
                    if (qStudyQuestions && qStudyQuestions.length > 0) {

                        qStudyQuestions.map((sq: StudyQuestionType, index: number) => {
                            let keys = sq.frequency_keys;
                            let values = sq.frequency_values;
                            let max_value = 1;
                            let minV = Number(sq.min_value);
                            let maxV = Number(sq.max_value);
                            if (values) {
                                max_value = Math.max(...values.map((v) => Number(v)));

                            }
                            let datakey: string = 'study' + (
                                                  index + 1
                            );
                            if (keys && values && keys.length > 0) {
                                let count: number;
                                sq.count ? count = sq.count : count = values?.reduce(
                                    (partialSum, a) => partialSum + Number(a), 0);
                                for (let i = 0; i < keys?.length; i++) {

                                    let obj: { [key: string]: number } = {};
                                    if (!isNaN(Number(values[i])) && !isNaN(Number(keys[i]))) {

                                        obj[datakey] = Number(values[i]) / count;
                                        let x = Number(keys[i]);
                                        obj['x'] = transformDataX(x, minV, maxV);
                                        data.push(obj);
                                    }

                                }
                                //    this is only to fix a bug with recharts (which only happens for the case of a
                                // single study)
                                if (qStudyQuestions && qStudyQuestions.length == 1) {
                                    let obj = {'study2': -1, x: 1}
                                    data.push(obj);
                                }
                            }

                        })

                    }
                }

                data.sort(function (a, b) {
                    return b.x - a.x
                });
                return data;
            }
        }

    }

    function onlyUnique(value: any, index: number, array: any[]) {
        return array.indexOf(value) === index;
    }

    const range = (start: number, stop: number) => {
        let step: number = Math.floor((
                                          stop - start
                                      ) / Math.pow(10, Utils.numDigits(stop - start) - 1));
        return Array.from({
                              length: (
                                          stop - start
                                      ) / step + 1
                          }, (_, i) => start + i * step);
    }
    const rangeTicks = (minV: number, maxV: number, n: number) => {
        let step = (
                       maxV - minV
                   ) / n
        return Array.from({length: n}, (_, i) => minV + step * i);
    }


    const getActiveStudyProps = (studyNum: number) => {
        if (qStudyQuestions && qStudyQuestions[studyNum - 1] && maxX) {
            let sQ: StudyQuestionType = qStudyQuestions[studyNum - 1];
            if (sQ.frequency_keys) {
                let ticks: number[];
                ticks = sQ.frequency_keys?.filter((k) => !isNaN(Number(k))).map((k) => Number((
                                                                                                  Number(k) * maxX
                                                                                              ).toFixed(
                    Utils.decimalsNum(minX, maxX))));
                if (props.dataType === 'non-negative number') {
                    // calculate how many ticks we need
                    let ticksExtra = rangeTicks(minX, maxX, 10);
                    ticks = ticks.concat(ticksExtra).map(t => Math.round(t)).filter(onlyUnique);
                }
                ticks.sort(function (a, b) {
                    return b - a
                });
                calcBarWidth();
                return {
                    studyNum: studyNum,
                    ticks   : ticks,
                }
            }
            else {
                return null
            }

        }
        else {
            return null
        }
    }

    const generateTicks = (min: number, max: number, count: number = 10) => {
        const step = (
                         max - min
                     ) / (
                         count - 1
                     );
        return Array.from({length: count}, (_, i) => Math.round(min + i * step));
    };
    const calcTicksForNoStudies = () => {
        return generateTicks(minX ?? 0, maxX ?? 100, 10);
    };

    const updateBarWidth = () => {
        if (qStudyQuestions && activeStudy) {
            let barW = calcBarWidth();
            if (barW) {
                setBarWidth(barW);
            }
        }
    }

    const calcBarWidth = () => {
        let barW;
        let [w, h] = refSize;
        if (chartRef.current && activeStudy && refSize[0] > 0 && qStudyQuestions) {
            let keys = qStudyQuestions[activeStudy.studyNum - 1].frequency_keys;
            if (keys !== undefined && maxX !== undefined) {
                let keysFloat = keys.filter(k => !!Number(k)).map(k => Number(k) * maxX);
                let bucketsNum = keys.length

                let ticks = keysFloat.sort(function (a, b) {
                    return a - b;
                }).filter(t => t > 0).filter(onlyUnique);
                let ticksNum = ticks.length
                // in case buckets are not equal, we take the lowest width of the bucket

                let minBucketRange = Math.min(...ticks.map((t: number, index: number): number => {
                    if (index === 0) {
                        return (
                            t - minX
                        );
                    }
                    else {
                        return (
                            t - ticks[index - 1]
                        )
                    }
                }))
                let altNumOfBackets = (
                                          maxX - minX
                                      ) / minBucketRange
                // if (props.dataType == 'likert scale') altNumOfBackets = 0
                let del: number

                if (props.dataType === 'non-negative number') del = Math.max(ticksNum, bucketsNum, altNumOfBackets)
                if (props.dataType === 'yes or no') {
                    del = 1;
                }
                else {
                    del = Math.max(ticksNum, bucketsNum, altNumOfBackets) - 1
                }
                barW = (
                           w - 72 - 50
                       ) / del

            }
        }
        else {
            updateSize();
        }
        return barW;
    }

    const changeActiveStudy = (e: any) => {
        // number of the study to set as active is coded in the link's title
        let str = e.target.id;
        let studyToSetActive = getActiveStudyProps(Number(str))

        setActiveStudy(studyToSetActive);

    }

    const renderColorfulLegendText = (value: string, entry: any, index: any) => {
        const {color} = entry;
        const defaultColor = "#000"; // Fallback color
        let colorStr = color; // Default to entry's color

        if (index > 0 && activeStudy?.studyNum) {
            colorStr = UiConfig.colors[activeStudy.studyNum - 1] || defaultColor;
        }

        return <span style={{color: colorStr, opacity: 1}}>{value}</span>;
    };

    const formatXAxis = (value: number): string => {
        if (props.dataType === 'yes or no') {
            return value === 0.25 ? 'No' : value === 0.75 ? 'Yes' : String(value);
        }
        if (props.dataType === 'likert scale') {
            return (
                value * 100
            ).toFixed(0); // Convert to integer format
        }
        return new Intl.NumberFormat("en-US", {
            style         : "decimal",
            notation      : "compact",
            compactDisplay: "short",
        }).format(value);
    };

    useEffect(() => {
        calcMinMaxX();
    }, [qStudyQuestions]);

    useEffect(() => {
        setChartData(calcChartData);
    }, [maxX]);

    useEffect(() => {
        if (chartData) {
            let maxCalculatedY;
            if (showPrediction) {

                // Include both prediction (y) and study data when `showPrediction` is true
                maxCalculatedY = Math.max(
                    ...chartData.map((d) => {
                        const barKeys = Object.keys(d).filter((key) => key.startsWith('study'));
                        const barMaxes = barKeys.map((key) => d[key] || 0);
                        return Math.max(d.y || 0, ...barMaxes);
                    })
                );
            }
            else {
                // Only consider study data when `showPrediction` is false
                maxCalculatedY = Math.max(
                    ...chartData.map((d) => {
                        const barKeys = Object.keys(d).filter((key) => key.startsWith('study'));
                        return Math.max(...barKeys.map((key) => d[key] || 0));
                    })
                );
            }

            const minCalculatedY = Math.min(
                ...chartData.map((d) => d.y || 0)
            );

            setMaxY(maxCalculatedY); // Update maxY based on condition
            setMinY(minCalculatedY);
            console.log("GD", maxCalculatedY)
        }

        updateSize();
        updateBarWidth();
        forceUpdate();

        console.log(chartData)
        console.log(maxY)
    }, [chartData, showPrediction]);

    useEffect(() => {
        if (chartData && props.dataType === 'yes or no' && maxY) {
            setChartData((prevData) =>
                             (
                                 prevData || []
                             ).map((dataPoint) => {
                                 // Only modify objects where `x` exists
                                 if (Object.prototype.hasOwnProperty.call(dataPoint, 'x')) {
                                     return {
                                         ...dataPoint,
                                         y: dataPoint.y !== undefined ? dataPoint.y * maxY : dataPoint.y, // Adjust "y"
                                                                                                          // only
                                     };
                                 }
                                 // Leave other data points unchanged (if they don't have 'x')
                                 return dataPoint;
                             })
            );
        }
    }, [maxY]);

    useEffect(() => {
        updateBarWidth();
    }, [chartRef.current]);

    useEffect(() => {
        if (
            qStudyQuestions &&
            qStudyQuestions.length > 0 &&
            maxX !== undefined &&
            maxX > 0 &&
            activeStudy === null
        )
        {
            const initialStudy = getActiveStudyProps(1); // Get the first study as the active one
            setActiveStudy(initialStudy);
        }
        else {
            // Log missing dependencies for debugging
            if (!qStudyQuestions || qStudyQuestions.length === 0) {
            }
            if (maxX === undefined || maxX <= 0) {
            }
        }
    }, [qStudyQuestions, maxX]);


    useEffect(() => {
        updateBarWidth();
    }, [refSize]);

    useEffect(() => {
        updateSize();
        updateBarWidth();
    }, [activeStudy]);

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        // return window.removeEventListener('resize', updateSize);

        return () => {
        }
    }, [])
    const truncatedLabel = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    };
    return (
        <div>
            <div className="pt-2">
                {/* Center the heading */}
                <div className="d-flex justify-content-center">
                    <h4 className="statsChartHeading">
                        {props.qStatistics ? UI_STRINGS.questionTabs.statsChartHeading : ""}
                    </h4>
                </div>
                <div className='d-flex justify-content-between statsControls '>
                    <div>
                        {(
                         qStudyQuestions && qStudyQuestions.length > 1
                         ) && (
                             <div className='lead'>
                                 {/*<span className='p-1 m-1'>Show:</span>*/}
                                 {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                     <button key={`${sq.id}-${index}`} className={'p-1 m-1 btn btn-link'}
                                             id={String(index + 1)} style={{
                                         color          : (
                                                              activeStudy && index === (
                                                                  activeStudy.studyNum - 1
                                                              )
                                                          ) ? 'black' : UiConfig.colors[index],
                                         backgroundColor: (
                                                              activeStudy && index === (
                                                                  activeStudy.studyNum - 1
                                                              )
                                                          ) ? UiConfig.backgrounds[index] : 'white',
                                         textDecoration : (
                                                              activeStudy && index === (
                                                                  activeStudy.studyNum - 1
                                                              )
                                                          ) ? 'none' : 'underline',
                                         fontWeight     : (
                                                              activeStudy && index === (
                                                                  activeStudy.studyNum - 1
                                                              )
                                                          ) ? 'bolder' : 'unset',
                                         cursor         : (
                                                              activeStudy && (
                                                                  index === (
                                                                      activeStudy.studyNum - 1
                                                                  )
                                                              )
                                                          ) ? 'auto' : 'pointer',

                                     }}
                                             onClick={changeActiveStudy}>{'Study ' + (
                                                                          index + 1
                                     )}</button>
                                 ))}
                             </div>
                         )}
                    </div>

                    {/* Position the toggle switch below and to the right */}
                    {props.qStudyQuestions && props.qStudyQuestions.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                            <Form>
                                <Form.Switch
                                    id="showPredictionToggle"
                                    className="d-flex align-items-center"
                                    label="Show prediction"
                                    onChange={() => setShowPrediction(!showPrediction)}
                                    checked={showPrediction}
                                    disabled={!qStudyQuestions || qStudyQuestions.length === 0}
                                />
                            </Form>
                        </div>
                    )}
                </div>


            </div>
            {maxX && chartData && chartData.length > 0 && (
                <div className="graphWrapper" ref={chartRef} style={{}}>
                    <ResponsiveContainer>

                        <ComposedChart
                            margin={{
                                top   : showPrediction ? 0 : 30,
                                right : 10,
                                left  : 0,
                                bottom: 50,
                            }}
                            data={chartData}


                        >
                            {props.dataType !== 'yes or no' && (
                                <CartesianGrid strokeDasharray="3 3"/>
                            )}


                            {showPrediction && (
                                <Legend
                                    verticalAlign="top"
                                    align="right"
                                    wrapperStyle={{
                                        marginBottom: 0,
                                        marginLeft  : 60,
                                        padding     : 0,
                                        height      : 30,
                                    }}
                                    formatter={renderColorfulLegendText}
                                />
                            )}
                            <YAxis
                                dataKey="y"
                                domain={[0, maxY]}
                                type="number"
                                interval={0}
                                label={{
                                    value   : UI_STRINGS.questionTabs.statsChartYLabel,
                                    style   : {textAnchor: 'middle', fontSize: '16px', fill: '#5A5A5A'},
                                    angle   : -90,
                                    position: 'insideLeft',
                                    offset  : 30,
                                }}
                                allowDataOverflow={true}
                                strokeWidth={minX < 0 ? 0 : 1}
                                tick={false}
                            />

                            <XAxis
                                dataKey="x"
                                domain={[0, maxX]}
                                interval="preserveStartEnd"
                                type="number"
                                label={
                                    props.dataType === 'yes or no'
                                    ? undefined // No label for "yes or no"
                                    : {
                                            key     : 'xAxisLabel',
                                            style   : props.dataType === 'likert scale'
                                                      ? {textAnchor: 'middle', fontSize: '17px', fill: '#5A5A5A'}
                                                      : {textAnchor: 'middle', fontSize: '16px', fill: '#5A5A5A'},
                                            value   : truncatedLabel(
                                                props.dataType === 'non-negative number'
                                                ? props.questionText
                                                : 'Level of agreement',
                                                50 // Set maxLength as needed
                                            ),
                                            angle   : 0,
                                            position: 'bottom',
                                            offset  : props.dataType === 'likert scale' ? 30 : 5,
                                        }
                                }
                                allowDataOverflow={true}
                                strokeWidth={minY < 0 ? 0 : 1}
                                ticks={props.dataType === 'yes or no' ? [0.25, 0.75] : activeStudy ? activeStudy.ticks
                                                                                                   : calcTicksForNoStudies()}
                                tickFormatter={formatXAxis} tickLine={props.dataType !== 'yes or no'}
                                tick={{
                                    fontSize  : props.dataType === 'yes or no' ? 18 : 14, // Larger font for 'yes or no'
                                    textAnchor: 'middle',
                                }}
                            >
                            </XAxis>
                            {(
                                 props.dataType === 'likert scale'
                             ) && (
                                 <>
                                     {/* "Disagree" under the first tick */}
                                     <text
                                         x={refSize[0] * 0.15} // Align to the first tick position (approximately)
                                         y={refSize[1] - 40} // Just under the first tick
                                         textAnchor="middle" // Center-align the label with the tick
                                         style={{
                                             fontSize: '16px',
                                             fill    : 'gray',
                                         }}
                                     >
                                         {props.dataType === 'likert scale' ? 'Disagree' : 'No'}
                                     </text>

                                     {/* "Agree" under the last tick */}
                                     <text
                                         x={refSize[0] * 0.95} // Align to the last tick position (near the end)
                                         y={refSize[1] - 40} // Just under the last tick
                                         textAnchor="middle" // Center-align the label with the tick
                                         style={{
                                             fontSize: '16px',
                                             fill    : 'gray',
                                         }}
                                     >
                                         {props.dataType === 'likert scale' ? 'Agree' : 'Yes'}
                                     </text>
                                 </>
                             )}
                            {(
                             qStudyQuestions && activeStudy && qStudyQuestions[activeStudy.studyNum - 1].data_type
                             === 'non-negative number'
                             ) && (
                                 <XAxis/>
                             )
                            }

                            {minY < 0 && (
                                <ReferenceLine
                                    y={0}
                                    stroke="gray"
                                    strokeWidth={1.5}
                                    strokeOpacity={0.65}
                                />
                            )}
                            {minX < 0 && (
                                <ReferenceLine
                                    x={0}
                                    stroke="gray"
                                    strokeWidth={1.5}
                                    strokeOpacity={0.65}
                                />
                            )}

                            {showPrediction && (
                                <Area
                                    type="monotone"
                                    dataKey="y"
                                    stroke="#ff7700"
                                    fill="#efefef"
                                    strokeWidth={2}
                                    name="predicted frequencies"
                                    dot={false}
                                    activeDot={false}
                                    legendType="plainline"
                                />
                            )}

                            {activeStudy && barWidth && (
                                <Bar dataKey={'study' + activeStudy.studyNum}
                                     key={`study-bar-${activeStudy.studyNum}`}
                                     barSize={barWidth}
                                     fill={UiConfig.backgrounds[activeStudy.studyNum - 1]}
                                     stroke={UiConfig.backgrounds[activeStudy.studyNum - 1]} name="study data"
                                     opacity={1}
                                />
                            )}

                        </ComposedChart>


                    </ResponsiveContainer>


                </div>
            )

            }</div>

    );
}
export default StatsChart;
