import {NavigateFunction} from "react-router-dom";

/**
 * Navigates to the 404 page with relevant state.
 * @param navigate - React Router's navigate function
 */
export function navigateTo404(navigate: NavigateFunction) {
    const originalPath = window.location.pathname;
    navigate("/404", {
        replace: true,
        state  : {
            originalPath: originalPath,
        }
    });
}