import {useQuery}          from "react-query";
import QuestionType        from "../types/question.type";
import QuestionDataService from "../services/question.service";
import {useUser}           from "../context/UserProvider/UserProvider";
import UiConfig            from "../common/ui-config";

export function useActualForMostCorrelated(
    org_id: string, handleActualForMostCorrelated: (res: any) => void, handleError: any,
    baseQuestion: QuestionType | null, custom_text: string | null | undefined,
    mostCorrelatedResult: QuestionType[] | null
)
{
    const {
              isFetching: isLoadingActualForMostCorrelated,
              refetch   : requestActualForMostCorrelated
          } = useQuery<number[], Error>(
        "query-request-actual-most-correlated",
        async () => {
            if (mostCorrelatedResult && mostCorrelatedResult.length > 0 && !custom_text) {
                if (baseQuestion && !custom_text) {
                    const mostCorrTexts = mostCorrelatedResult.map(e => e.canonical_text);
                    return await QuestionDataService.getStudyCorrelationsForTexts(
                        org_id, baseQuestion.canonical_text, mostCorrTexts);
                }
            }
        },
        {
            enabled  : false,
            retry    : 2,
            onSuccess: (res) => {
                handleActualForMostCorrelated(res);
            },
            onError  : (err: any) => handleError(err, null),
        }
    )

    return {isLoadingActualForMostCorrelated, requestActualForMostCorrelated};
}
