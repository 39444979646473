import {encryptData, decryptData} from "./cryptoUtils";

/**
 * Write data to localStorage with encryption.
 * @param key LocalStorage key under which data will be stored.
 * @param data Data to be saved in localStorage (can be any serializable object).
 */
export const writeToLocalStorage = (key: string, data: any): void => {
    try {
        const encryptedData = encryptData(data);
        localStorage.setItem(key, encryptedData);
    }
    catch (error) {
        console.error(`Failed to write data to localStorage with key "${key}"`, error);
    }
};

/**
 * Read data from localStorage and decrypt it.
 * @param key LocalStorage key from which data will be retrieved.
 * @returns Decrypted data from localStorage, or null if not found or an error occurs.
 */
export const readFromLocalStorage = (key: string): any | null => {
    const encryptedData = localStorage.getItem(key);
    if (!encryptedData) return null;

    try {
        const decryptedData = decryptData(encryptedData);
        return JSON.parse(decryptedData);
    }
    catch (error) {
        console.error(`Failed to decrypt data from localStorage with key "${key}"`, error);
        return null;
    }
};

/**
 * Remove data from localStorage by key.
 * @param key LocalStorage key to be removed.
 */
export const removeFromLocalStorage = (key: string): void => {
    try {
        localStorage.removeItem(key);
    }
    catch (error) {
        console.error(`Failed to remove data from localStorage with key "${key}"`, error);
    }
};