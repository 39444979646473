import {useEffect, useRef, useState} from 'react';
import {useQuery}                    from 'react-query';
import StudyDataService              from '../services/study.service';
import StudyType                     from "../types/study.type";
import StudyQuestionType             from "../types/study-question.type";
import ReactGA                       from "react-ga4";
import QuestionType                  from "../types/question.type";
import QuestionDataService           from "../services/question.service";

export interface CorrelationDetails {
    correlation: number,
    study_id: string,
    sample_size: number,
    correlatedSQ?: StudyQuestionType | undefined,
}

export function useStudyDetails(
    qStudyQuestions?: StudyQuestionType[] | null)
{
    const [studyInfo, setStudyInfo] = useState<StudyType[] | undefined>();
    const [requestedIndex, setRequestedIndex] = useState<number | undefined>();
    const [showStudyDetails, setShowStudyDetails] = useState(false);
    const [correlationDetails, setCorrelationDetails] = useState<CorrelationDetails | undefined>();
    const [correlatedQ, setCorrelatedQ] = useState<QuestionType | null>(null);
    const [correlatedStudyQuestions, setCorrelatedStudyQuestions] = useState<StudyQuestionType[] | undefined>();
    const [correlatedSQtoDisplay, setCorrelatedSQtoDisplay] = useState<StudyQuestionType | undefined>();
    const handleClose = () => {
        setRequestedIndex(undefined);
        setShowStudyDetails(false)
        setCorrelationDetails(undefined);
    };

    function handleStudyDetailsResults(res: any) {

        if (res.studies && requestedIndex !== undefined) {
            // Sort studies by sample_size in descending order
            const sortedStudies = res.studies.sort((a: any, b: any) => b.sample_size - a.sample_size);
            setStudyInfo(sortedStudies);
        }
        else {
            setStudyInfo(res.studies);
        }
    }

    const handleShowStudyDetails = (
        index: number | undefined, correlationItem: any, correlatedSQ: QuestionType | undefined) => {
        ReactGA.send({
                         hitType      : 'event',
                         eventCategory: '...',
                         eventAction  : 'User clicked Study Info button'
                     });

        console.log('handleShowStudyDetails', index, correlationItem, correlatedSQ);
        if (index !== undefined) setRequestedIndex(index);
        if (studyInfo && requestedIndex === undefined && correlationItem) {
            let idx = studyInfo.findIndex((s: StudyType) => s.id === correlationItem.study_id);
            if (idx !== -1) {
                setRequestedIndex(idx);
                setCorrelationDetails(correlationItem);
            }
            else {
                console.error('Study not found for correlation item');
            }
        }
        if (studyInfo && correlatedSQ && correlatedSQ.universal_q_id) {
            setCorrelatedQ(correlatedSQ);
        }
        setShowStudyDetails(true);
    };

    useEffect(() => {
        if (correlatedQ && correlatedQ.universal_q_id) {
            fetchStudyQuestions()
                .catch(err => console.error("Failed to fetch study questions:", err));
        }
        else {
        }
    }, [correlatedQ]);


    const {isFetching: isLoadingStudyQuestions, refetch: fetchStudyQuestions} = useQuery<StudyQuestionType[], Error>(
        ["query-study-details-correlated-study-questions", correlatedQ?.universal_q_id], // Unique query key
        async () => {
            if (!correlatedQ?.universal_q_id) {
                return [];
            }
            return await QuestionDataService.getQStudyQuestions("main", correlatedQ.universal_q_id);
        },
        {
            enabled  : !!correlatedQ?.universal_q_id,
            onSuccess: handleStudyQSResult,
            onError  : (err) => console.error("Error fetching study questions:", err),
        }
    );

    function handleStudyQSResult(res: any) {
        if (res) {
            setCorrelatedStudyQuestions(res);
        }
    }

    useEffect(() => {
        if (correlatedStudyQuestions && correlatedStudyQuestions.length > 0 && correlationDetails) {
            const sq = correlatedStudyQuestions.filter(
                (sq: StudyQuestionType) => sq.study_id === correlationDetails.study_id);
            if (sq && sq.length > 0) {
                setCorrelatedSQtoDisplay(sq[0]);
            }
        }
    }, [correlatedStudyQuestions])

    const {isFetching: isLoadingStudyDetails, refetch: goGetStudyDatails} = useQuery<StudyQuestionType[], Error>(
        "query-study-details",
        async () => {
            let org_id = 'main';
            if (qStudyQuestions && qStudyQuestions.length > 0) {
                let ids = qStudyQuestions.map(s => s.study_id);
                return await StudyDataService.getStudiesByIds(org_id, ids);
            }
            else if (qStudyQuestions && qStudyQuestions.length === 0) {
                return await StudyDataService.getAllStudies(org_id);
            }
            throw new Error('No study questions provided');
        },
        {
            enabled  : false,
            retry    : 3,
            onSuccess: (res) => handleStudyDetailsResults(res),
            onError  : (err: any) => console.log(err)
        }
    );

    useEffect(() => {
    }, [requestedIndex]);

    useEffect(() => {
        if (qStudyQuestions && !studyInfo) {
            goGetStudyDatails().then(() => {
            }).catch(err => console.error('Failed to fetch study details'));
        }
    }, [qStudyQuestions]);

    useEffect(() => {
        return () => {
            setCorrelatedStudyQuestions(undefined);
            setCorrelatedQ(null);
        }

    }, [])

    return {
        studyInfo,
        isLoadingStudyDetails,
        requestedIndex,
        correlationDetails,
        showStudyDetails,
        handleClose,
        handleShowStudyDetails,
        correlatedSQtoDisplay,
    };
}