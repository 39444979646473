import React                        from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.scss";

import QuestionSearch                    from "./components/question-search.component";
import Question                          from "./components/question.component";
import {withRouter}                      from "./common/with-router";
import {promptForQPage}                  from "./components/UIElements";
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import NotFoundPage                      from "./static/not-found-page";
import Terms                             from "./static/terms";
import Privacy                           from "./static/privacy";
import About                             from "./static/about";
import TopNav                            from "./components/authAndNav/TopNav";
import AppSettings                       from "./common/app-settings";
import AppProviders                      from "./context/AppProviders";


const App = () => {
    const {route} = useAuthenticator(context => [context.route]);

    const RobotsTxt = () => {
        const debugHostnames = AppSettings.debugHostnames;
        const isDebugEnv = debugHostnames.some(hostname => window.location.hostname.includes(hostname));

        // Dynamically generate robots.txt content
        const robotsContent = isDebugEnv
                              ? `User-agent: *\nDisallow: /`
                              : `User-agent: *\nAllow: /`;

        // Serve it as plain text
        return (
            <pre style={{whiteSpace: 'pre-wrap'}}>
               {robotsContent}
           </pre>
        );
    };

    return (
        <AppProviders>
            <div className="wrapper d-flex flex-column position-relative">
                <TopNav/>
                <div className="container mt-3 flex-grow-1">
                    <Routes>
                        <Route path="/"
                               element={<QuestionSearch/>}/>
                        <Route path="/questions/:id"
                               element={route === 'authenticated' ? <Question/> :
                                        <Authenticator initialState={"signUp"} components={promptForQPage}/>}/>
                        <Route path="/terms" element={<Terms/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/privacy-policy" element={<Privacy/>}/>
                        <Route path="/404" element={<NotFoundPage/>}/>
                        <Route path="*" element={<NotFoundPage/>}/>
                        <Route path="/robots.txt" Component={RobotsTxt}/>
                    </Routes>
                </div>
            </div>
        </AppProviders>
    );
}

export default withRouter(App);
