import React, {useState, FC, ChangeEvent, FormEvent, useEffect} from 'react';
import emailValidator                                           from 'email-validator';
import {Form, Button, Alert}                                    from 'react-bootstrap';
import {useQuery}                                               from "react-query";
import EmailService                                             from "../../services/emailService";
import Utils                                                    from "../../common/utils";
import ReactGA                                                  from "react-ga4";

const UI_STRINGS = require('../../common/ui-strings.json')

interface FeedbackFormProps {
    email?: string;
}

const FeedbackForm: FC<FeedbackFormProps> = ({email: initialEmail = ''}) => {
    const [email, setEmail] = useState<string>(initialEmail);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>('');
    const [result, setResult] = useState<string | null>(null);
    const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setIsEmailValid(emailValidator.validate(e.target.value));
        setIsButtonActive(emailValidator.validate(e.target.value) && feedback !== '');
    };
    const handleFeedbackChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(e.target.value);
        setIsButtonActive(isEmailValid && e.target.value !== '');
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (emailValidator.validate(email)) {
            ReactGA.event({
                              category: "Feedback",
                              action  : "User feedback: submit clicked",
                          })
            sendFeedback();
        }
        else {
            handleError('Please enter a valid email');
        }
    };

    const {
              isFetching: isLoadingSendFeedback,
              refetch   : sendFeedback
          } = useQuery<number[], Error>(
        "query-send-feedback",
        async () => {
            if (email && feedback && email.length > 0 && feedback.length > 0) {
                return await EmailService.sendFeedbackNotification(email, feedback);
            }
        },
        {
            enabled  : false,
            retry    : 2,
            onSuccess: (res) => handleEmailSent(res),
            onError  : (err: any) => handleError(err),
        }
    )

    function handleEmailSent(res: any) {
        console.log(Utils.formatResponse(res));
        if (res) {
            let res_str = Utils.formatResponse(res)
            let isEmailSentSuccessfully = res_str.includes("Email Sent Successfully");
            if (isEmailSentSuccessfully) {
                setResult('success');
                ReactGA.event({
                                  category: "Feedback",
                                  action  : "Feedback submitted successfully",
                              })
            }
        }
        else {
            ReactGA.event({
                              category: "Feedback",
                              action  : "Error: something prevented submitting feedback",
                          })
            setResult('Something went wrong, please try again later')
        }


    }

    function handleError(err: any) {
        console.log("Error!!!!!");
        setResult(err);
        return false;
    }

    useEffect(() => {
        const emailStorage = localStorage.getItem('userEmail');
        if (emailStorage) {
            setEmail(emailStorage);
            setIsEmailValid(emailValidator.validate(emailStorage));
        }
        else {
            setIsEmailValid(false);
        }
    }, []);
    useEffect(() => {
        setIsButtonActive(isEmailValid && feedback !== '');
    }, [isEmailValid, feedback]);
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>{UI_STRINGS.labels.feedback_email}</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder=""
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{UI_STRINGS.labels.feedback_message}</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder=""
                        rows={5}
                        value={feedback}
                        onChange={handleFeedbackChange}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit"
                        disabled={!isButtonActive || result !== null || isLoadingSendFeedback}>
                    Submit
                </Button>
                {result && (
                    <Alert style={{
                        display        : 'inline-block',
                        border         : 'none',
                        backgroundColor: 'transparent'
                    }}
                           variant={result == 'success' ? 'success' : 'danger'}>{result == 'success' ? 'Message sent!'
                                                                                                     : result}</Alert>
                )}

            </Form>
        </div>
    );
};
export default FeedbackForm;
