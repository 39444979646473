import axios, {AxiosError, AxiosRequestConfig} from "axios";
import UiConfig                                from "./ui-config"
import AppSettings                             from "./app-settings";

const currentUrl = window.location.href;
// const is_dev = currentUrl.search("localhost") >= 0;
const is_dev = AppSettings.useDevBackEnd;
let baseUrl;
let xApiKey = process.env.REACT_APP_XAPIKEY;

is_dev ? baseUrl = "https://abj273e2b3.execute-api.us-east-2.amazonaws.com/stage" : baseUrl
    = "https://api.personalitymap.io"
is_dev ? xApiKey = "stage-" + xApiKey : xApiKey = '' + xApiKey
const apiClient = axios.create({
                                   baseURL: baseUrl,
                                   headers: {
                                       "Content-type": "application/json",
                                       "x-api-key"   : xApiKey,

                                   },
                                   timeout: 29000, // 29 seconds, matches API Gateway timeout
                               });
// Adding response interceptor to suppress logging for 404 errors

let retryCount = 0;
const MAX_RETRY = 3;

apiClient.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if ((
                error.response?.status === 504 || error.code === "ERR_NETWORK"
            ) && retryCount < MAX_RETRY)
        {
            retryCount++;
            console.warn(`Retry attempt ${retryCount} after encountering ${error.response?.status || error.code}`);
            return apiClient.request(error.config as AxiosRequestConfig); // Retry request
        }
        console.error("Request failed after retries or non-retryable error:", error);
        retryCount = 0; // Reset retry count after success or final failure
        return Promise.reject(error);
    }
);
export default apiClient;
