import React, {useEffect, useLayoutEffect, useRef, useState}      from 'react';
import {Button, Dropdown, Form}                                   from 'react-bootstrap';
import CytoscapeDiagram                                           from './cytoscapeDiagram';
import NetworkDiagramData                                         from '../../types/networkDiagram.type';
import {getConnectionsDiagramElements, getNetworkDiagramElements} from './dataTransforms';
import UiConfig                                                   from "../../common/ui-config";
import ConnectionsDiagramData                                     from "../../types/connectionsDiagram.type";

type Props = {
    data: NetworkDiagramData | ConnectionsDiagramData;
    tabType: TabType;
    initialNodesLimit: number;
};

type TabType = 'network' | 'connections';
const cytoscapeLayoutSet: Record<TabType, string[]> = UiConfig.cytoscapeLayoutSet;
const REGRESSION_THRESHOLD = 0.1;

const NetworkComponent = (props: Props) => {
    const {tabType, data, initialNodesLimit} = {...props};
    const layoutKeys: string[] = cytoscapeLayoutSet[props.tabType];
    const seenEdges = new Set<string>();
    const [showCorrelation, setShowCorrelation] = useState<boolean>(false);
    const [edgeWidthCoefficient, setEdgeWidthCoefficient] = useState<number>(10);
    const [nodesNumber, updateNodesNumber] = useState<number>(initialNodesLimit);
    const [elements, setElements] = useState<{}[]>([]);
    const [reload, setReload] = useState<boolean>(false);
    const [layoutName, setLayoutName] = useState<string>(tabType === 'network' ? 'circle' : 'preset');
    const [loading, setLoading] = useState<boolean>(true);
    const [containerWidth, setContainerWidth] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement>(null);

    // Type guards
    function isConnectionsDiagramData(data: any): data is ConnectionsDiagramData {
        return (
                   data as ConnectionsDiagramData
               ).statements !== undefined;
    }

    function isNetworkDiagramData(data: any): data is NetworkDiagramData {
        return Array.isArray(data) && data.every(item => isElement(item));
    }

    function isElement(data: any): data is Element {
        return (
                   data as Element
               ) !== undefined;
    }

    function getLatestNodeId(): string {
        if (isConnectionsDiagramData(data)) {
            return data.connections[nodesNumber - 1] ? data.connections[nodesNumber - 1].text : '';
        }
        else if (isNetworkDiagramData(data)) {
            return data[nodesNumber - 1] ? data[nodesNumber - 1].base_question_text : '';
        }
        else {
            return '';
        }
    }

    useLayoutEffect(() => {
        const updateSize = () => {
            setPageHeight(window.innerHeight);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    useEffect(() => {
        let newElements;

        if (tabType === 'connections' && isConnectionsDiagramData(data)) {
            newElements = getConnectionsDiagramElements(
                data, nodesNumber, seenEdges, REGRESSION_THRESHOLD, containerWidth, sizeToUse);

        }
        else if (isNetworkDiagramData(data)) {
            newElements = getNetworkDiagramElements(data, nodesNumber, seenEdges);
        }
        else {
            newElements = []; // handle case when data is neither type
        }

        setElements(newElements);
    }, [nodesNumber, data]);

    useEffect(() => {
        const slider = document.getElementById('networkEdgeWidthSlider');
        if (!slider) return;
        const handleInput = (event: any) => setEdgeWidthCoefficient(event.target.value);
        slider.addEventListener('input', handleInput);
        return () => slider.removeEventListener('input', handleInput);
    }, []);

    const [pageHeight, setPageHeight] = useState<number>(0);
    const minHeight = 600;
    const sizeToUse = Math.max(minHeight, pageHeight);

    useEffect(() => {
        if (elements && sizeToUse !== 0 && pageHeight !== 0) {
            setLoading(false);
        }
    }, [elements, sizeToUse, pageHeight, edgeWidthCoefficient]);

    return (
        <div className="cyContainer d-flex justify-content-between flex-column" style={{height: sizeToUse + 'px'}}>
            <div className="cyControls d-flex flex-column flex-sm-row" style={{maxWidth: '80%'}}>
                <Dropdown className="dropdown pe-4" onSelect={(e) => {
                    if (e !== null) {
                        setLayoutName(e);
                    }
                }}>
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                        Select Layout
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {layoutKeys.map((l) => (
                            <Dropdown.Item key={l} eventKey={l} active={layoutName === l}>
                                {l}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                <div className="pe-4">
                    Edge width
                    <input type="range" className="form-range" min="1" max="25" id="networkEdgeWidthSlider"
                           style={{border: 'none'}} defaultValue="10"
                    />
                </div>
                <div style={{lineHeight: 1, paddingRight: '1rem', paddingTop: '5px'}}
                     className="d-none d-lg-flex">
                    <Form.Check
                        type="checkbox"
                        id="correlationCheckbox"
                        label={<span>Show<br/>correlations</span>}
                        checked={showCorrelation}
                        onChange={(e) => setShowCorrelation(e.target.checked)}
                    />
                </div>
                <Button variant="outline-primary" className="mb-3 me-2" style={{lineHeight: 1}}
                        onClick={() => {
                            seenEdges.clear();
                            let coreElementsNum = 0;
                            if (tabType === 'connections' && isConnectionsDiagramData(data)) {
                                coreElementsNum = data.connections.length;
                            }
                            if (tabType === 'network' && isNetworkDiagramData(data)) {
                                coreElementsNum = data.length;
                            }
                            if (nodesNumber + 1 <= coreElementsNum) {
                                updateNodesNumber(nodesNumber + 1);
                                setReload(true); // Trigger reload for layout application
                            }
                        }}
                        disabled={nodesNumber >= (
                            isConnectionsDiagramData(data) ? data.connections.length : data.length
                        )}
                >
                    Add node
                </Button>
                <Button variant="outline-primary" className="mb-3 me-2" style={{lineHeight: 1}}
                        onClick={() => {
                            nodesNumber === initialNodesLimit ? setReload(!reload) : updateNodesNumber(
                                initialNodesLimit);
                        }}
                >Reset</Button>
            </div>
            <CytoscapeDiagram
                elements={elements}
                layoutName={layoutName}
                showCorrelation={showCorrelation}
                edgeWidthCoefficient={edgeWidthCoefficient}
                nodesLimit={nodesNumber}
                latestNodeId={getLatestNodeId()}
                reload={reload}
                loading={loading}
                onLayoutApplied={() => setReload(false)}
                initialNodesNum={initialNodesLimit}
                // Reset reload flag after layout application
            />
        </div>
    );
};

export default NetworkComponent;