import React, {useState}   from "react";
import StatsChart          from "./statsChart";
import StatsTable          from "./statsTable";
import RelatedArticles     from "./relatedArticles.component";
import StudyQuestionType   from "../../types/study-question.type";
import QuestionType        from "../../types/question.type";
import {Form}              from "react-bootstrap";
import useWindowDimensions from "../../common/useWindowDimentions";
import Utils               from "../../common/utils";

interface StatisticsSectionProps {
    qStatistics: any; // Replace `any` with the appropriate type
    qStudyQuestions: any; // Replace `any` with the appropriate type
    baseQuestion: any; // Replace `any` with the appropriate type
    tabKey: string;
    handleShowStudyDetails: (
        index: number | undefined,
        correlationItem: any,
        correlatedSQ: QuestionType | undefined
    ) => void;
}


const UI_STRINGS = require('../../common/ui-strings.json')

const StatisticsSection: React.FC<StatisticsSectionProps> = ({
                                                                 qStatistics,
                                                                 qStudyQuestions,
                                                                 baseQuestion,
                                                                 tabKey,
                                                                 handleShowStudyDetails,
                                                             }) => {
    const {height, width} = useWindowDimensions();
    const {STUDIES_CORR_NUM, STUDIES_NUM} = Utils.getStudyNumbers(width);


    const checkStudyConditions = (sq: StudyQuestionType) => {
        let othersPart = 0;

        if (sq.frequency_keys && sq.frequency_values) {
            const index = sq.frequency_keys.indexOf("others");
            if (index !== -1 && index < sq.frequency_values.length) {
                const othersVal = Number(sq.frequency_values[index]);
                const sumVal = sq.frequency_values
                                 .filter(v => !isNaN(Number(v)))
                                 .reduce((partialSum, v) => partialSum + Number(v), 0);
                othersPart = othersVal / sumVal;
            }
        }

        return othersPart < 0.05;
    };

    const [qStudyQuestionsFiltered, setQStudyQuestionsFiltered] = useState<StudyQuestionType[] | null>(null);

    React.useEffect(() => {
        const filteredQuestions = qStudyQuestions?.filter(checkStudyConditions).slice(0, STUDIES_NUM);
        setQStudyQuestionsFiltered(filteredQuestions);
    }, [qStudyQuestions, STUDIES_NUM]);


    if (!qStatistics) return null;
    return (
        <div className="statsWrapper d-flex flex-column justify-content-start flex-wrap">
            <div id="chartEl" className="chart">

                {qStatistics && tabKey === "statistics" && (
                    <StatsChart
                        key={
                 baseQuestion && qStatistics && qStudyQuestionsFiltered
                 ? "statschart" + baseQuestion?.universal_q_id
                 : 1
                        }
                        qStatistics={qStatistics}
                        questionText={baseQuestion.canonical_text}
                        qStudyQuestions={qStudyQuestionsFiltered}
                        dataType={baseQuestion?.question_type || ""}
                    />
                )}
            </div>
            <StatsTable
                key={
                    baseQuestion && qStatistics && qStudyQuestions
                    ? baseQuestion?.universal_q_id
                    : 1
                }
                qStatistics={qStatistics}
                qStudyQuestions={qStudyQuestions}
                dataType={baseQuestion?.question_type || ""}
                handleShowStudyDetails={handleShowStudyDetails}
            />
            {baseQuestion && baseQuestion.universal_q_id !== null && (
                <RelatedArticles question_text={baseQuestion.canonical_text}/>
            )}
        </div>
    );
};

export default StatisticsSection;