import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';
import {Amplify}                                                          from 'aws-amplify';
import {
    getCurrentUser,
    signOut as amplifySignOut,
    fetchUserAttributes as amplifyFetchUserAttributes,
    updateUserAttributes as amplifyUpdateUserAttributes,
}                                                                         from 'aws-amplify/auth';
import config                                                             from '../../amplifyconfiguration.json';
import {UserContextType, AccountSettings}                                 from "../../types/userContext.type";
import {Hub}                                                              from "aws-amplify/utils";
import {useAuthenticator}                                                 from "@aws-amplify/ui-react";
import useGA                                                              from "../../common/useGA4";
import UiConfig                                                           from "../../common/ui-config";

Amplify.configure(config);

type AuthUser = {
    userId: string;
    email: string;
    // Define other user properties here based on what `getCurrentUser` returns
    // For example: username: string; email: string; etc.
};

type UserAttributes = {
    sub: string;
    "custom:planExpiryDate"?: string;
    email_verified: string;
    "custom:accountType"?: string;
    "custom:org_id"?: string;
    email: string;
};


const defaultUserContext: UserContextType = {
    authenticatedUser: null,
    userAttributes   : null,
    accountParams    : {...UiConfig.accountTypeParams['free'], got_updated: false},
    authModalShow    : null,
    setAuthModalShow : () => {
    },
    afterSignIn      : () => {
    },
    onSignInClick    : () => {
    },
    onSignUpClick    : () => {
    },
    requireAuth      : () => {
    },
    signOut          : async () => {
    },
    loadingUser      : true, authInitialState: undefined,
};

const UserContext = createContext<UserContextType>(defaultUserContext);

interface UserProviderProps {
    children: ReactNode;
}

const convertToAuthUser = (user: any): AuthUser => {
    return {
        userId: user.userId,
        email : user.signInDetails.loginId,
        // Add other necessary properties mappings here
    };
};

export const UserProvider = ({children}: UserProviderProps) => {
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthUser | null | undefined>(undefined);
    const [userAttributes, setUserAttributes] = useState<UserAttributes | null | undefined>(undefined);
    const [accountParams, setAccountParams] = useState<AccountSettings>(
        defaultUserContext.accountParams as AccountSettings);
    const [authModalShow, setAuthModalShow] = useState<string | null>(null);
    const {toSignIn, toSignUp} = useAuthenticator();
    const {sendGAEvent, setGAUserId} = useGA();
    const [loadingUser, setLoadingUser] = useState(true);
    const [authInitialState, setAuthInitialState] = useState<"signIn" | "signUp" | "forgotPassword" | undefined>(
        undefined);
    const currentAuthenticatedUser = async () => {
        setLoadingUser(true); // Signal that we're retrieving the user
        try {
            const result = await getCurrentUser();
            const authUser = convertToAuthUser(result);
            setAuthenticatedUser(authUser); // User found
            fetchUserAttributes(authUser); // Fetch additional attributes
        }
        catch (error) {
            setAuthenticatedUser(null); // No user logged in
        }
        finally {
            setLoadingUser(false); // Done retrieving user
        }
    };

    const afterSignIn = () => {
        setAuthModalShow(null);
        setAuthInitialState(undefined);
        currentAuthenticatedUser();
        sendGAEvent('Auth', 'User logged in');
    };

    useEffect(() => {
        if (userAttributes && !userAttributes["custom:org_id"]) {
            updateDefaultAttributes().then(() => {
            });
        }
    }, [userAttributes])

    const onSignInClick = () => {
        toSignIn();
        sendGAEvent('Auth', 'User clicked login button');
        setAuthInitialState('signIn')
    }
    const onSignUpClick = () => {
        toSignUp();
        sendGAEvent('Auth', 'User clicked sign up button');
        setAuthInitialState('signUp')
    }
    const requireAuth = () => {
        toSignUp();
        setAuthInitialState('signUp');
        sendGAEvent('Auth', 'App requested authorization from user', undefined, true);
    }

    useEffect(() => {
        if (authInitialState) {
            setAuthModalShow(authInitialState);
        }

    }, [authInitialState])
    // todo: this is dangerous
    useEffect(() => {
        if (authModalShow === null) {
            setAuthInitialState(undefined);
        }
    }, [authModalShow])

    const updateDefaultAttributes = async () => {
        if (authenticatedUser) {
            try {
                const defaultUsagePlanAttributes = {
                    'custom:org_id'        : 'main',
                    'custom:accountType'   : 'free',
                    'custom:planExpiryDate': 'never',
                };
                await amplifyUpdateUserAttributes({userAttributes: defaultUsagePlanAttributes});
            }
            catch (error) {
            }
        }
    }


    useEffect(() => {
        currentAuthenticatedUser();
    }, []);

    const fetchUserAttributes = async (user: AuthUser) => {
        try {
            const result = await amplifyFetchUserAttributes();
            setUserAttributes(result as UserAttributes);
        }
        catch (error) {
            signOut();

        }
    };
    useEffect(() => {
        if (!loadingUser) {
            if (authenticatedUser) {
                setGAUserId(authenticatedUser.userId);
            }
            else {
                setGAUserId(null);
            }
        }
    }, [authenticatedUser, loadingUser]);

    useEffect(() => {
        if (userAttributes !== undefined) {
            // Use nullish coalescing operator (??) to handle undefined value
            const accountType: string = userAttributes?.["custom:accountType"] ?? 'free';
            if (accountType in UiConfig.accountTypeParams) {
                let accountParams = UiConfig.accountTypeParams[accountType];
                setAccountParams(accountParams)

            }
        }

    }, [userAttributes]);

    const signOut = async () => {
        localStorage.removeItem('userEmail');
        setAuthenticatedUser(null);
        try {
            await amplifySignOut();
            sendGAEvent('Auth', 'User signed out');
        }
        catch (error) {
            console.log('Error signing out: ', error);
        }
    };


    // todo!!!
    const listener = (data: any) => {
        // console.log("Amplify Hub listener triggered:", data.payload.event);
        switch (data.payload.event) {
            case 'signedIn':
                // console.log('User has signed in successfully.');
                afterSignIn();
                break;
            case 'signedOut':
                // console.log('User has signed out successfully.');
                break;
            case 'tokenRefresh':
                // console.log('Auth tokens have been refreshed.');
                break;
            case 'tokenRefresh_failure':
                // console.log('Failure while refreshing auth tokens.');
                break;
            case 'signInWithRedirect':
                // console.log('SignInWithRedirect API has resolved successfully.');
                break;
            case 'signInWithRedirect_failure':
                // console.log('Failure while trying to resolve SignInWithRedirect API.');
                break;
            case 'customOAuthState':
                // console.log('Custom state returned from CognitoHosted UI');
                break;
        }
    };


    useEffect(() => {
        const hubListenerCancelToken = Hub.listen('auth', listener);

        return () => {
            hubListenerCancelToken();
        };
    }, [authModalShow]);

    useEffect(() => {
        if (authenticatedUser && authenticatedUser.email) {
            localStorage.setItem('userEmail', authenticatedUser.email);
        }
    }, [authenticatedUser])

    return (
        <UserContext.Provider value={{
            authenticatedUser,
            userAttributes,
            accountParams,
            authModalShow,
            setAuthModalShow,
            afterSignIn,
            onSignUpClick,
            onSignInClick,
            requireAuth,
            signOut,
            loadingUser, authInitialState,
        }
        }>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);