import {Button}  from "react-bootstrap";
import React     from "react";
import {useUser} from "../../context/UserProvider/UserProvider";


const AuthPanel = () => {
    const {authenticatedUser, onSignUpClick, onSignInClick, signOut} = useUser();


    return (
        <div className="auth-panel">
            <a href="/about" className="btn btn-link auth-link logInButton">About</a>
            {authenticatedUser ? (
                <button className="btn btn-link logInButton" onClick={signOut}>Sign&nbsp;out</button>
            ) : (
                 <>
                     <button className="btn btn-link logInButton" onClick={onSignInClick}>Log in</button>
                     <Button size="lg" className="btn signUpButton" onClick={onSignUpClick}>
                         Sign&nbsp;up<span className="d-none d-lg-inline">&nbsp;for free</span>
                     </Button>
                 </>
             )}
        </div>
    )
}
export default AuthPanel;