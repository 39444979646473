import {useEffect, useState} from 'react';
import ReactGA               from 'react-ga4';
import AppSettings           from '../common/app-settings';
import {useUser}             from "../context/UserProvider/UserProvider";

const useGA = () => {
    const {authenticatedUser, userAttributes, loadingUser} = useUser();
    const [gaInitialized, setGAInitialized] = useState(false); // Track GA initialization status
    const debugHostnames = AppSettings.debugHostnames;
    useEffect(() => {
        // Only proceed if user information has fully loaded and GA is not yet initialized
        if (loadingUser || gaInitialized) {
            return;
        }
        const isDebugEnv = debugHostnames.some(hostname => window.location.hostname.includes(hostname));
        const isTeamAccount = (
            () => {
                let accountType = "free";
                if (userAttributes) {
                    accountType = userAttributes["custom:accountType"];
                }

                const isInTeamEmailList = AppSettings.teamAccounts.some(
                    email => authenticatedUser?.email?.includes(email));
                // Check if the user belongs to a team account
                return accountType === "team" || isInTeamEmailList;
            }
        )();

        if (!isDebugEnv && !isTeamAccount) {
            ReactGA.initialize(AppSettings.GAId);

            // Dynamically initialize GTM for production environments only
            const gtmScript = document.createElement('script');
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${AppSettings.GTMId}`;
            document.head.appendChild(gtmScript);

            // Mark GA as initialized
            setGAInitialized(true);
        }

        if (isDebugEnv) {
            setGAInitialized(true);
        }
        if (isTeamAccount) {
            setGAInitialized(true);
        }
    }, [authenticatedUser, userAttributes, loadingUser, gaInitialized]);

    const sendGAPageView = (page: string, title: string) => {
        if (!debugHostnames.some(hostname => window.location.hostname.includes(hostname))) {
            ReactGA.send({hitType: 'pageview', page, title});
        }
    };

    const sendGAEvent = (category: string, action: string, label?: string, nonInteraction?: boolean) => {
        if (!debugHostnames.some(hostname => window.location.hostname.includes(hostname))) {
            ReactGA.send({
                             hitType      : 'event',
                             eventCategory: category,
                             eventAction  : action,
                             eventLabel   : label,
                             nonInteraction,
                         });
        }
    };

    const setGAUserId = (userId: string | null) => {
        ReactGA.set({user_id: userId});
    };

    return {
        sendGAPageView,
        sendGAEvent,
        setGAUserId,
    };
};

export default useGA;