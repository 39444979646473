import React, {useEffect, useState}                   from "react";
import QuestionType                                   from "../../types/question.type";
import {LoadingSimple, OnlySurprisesSwitch, Surprise} from "../UIElements";
import ResultRow                                      from "./resultRow";
import ReactGA                                        from "react-ga4";
import {PredictCorrelationEntry}                      from "../../types/predictCorrelation.type";
import StudyQuestionType                              from "../../types/study-question.type";

const UI_STRINGS = require('../../common/ui-strings.json')


type Props = {
    predictCorrelationsData: PredictCorrelationEntry[],
    baseQuestion: QuestionType | null,
    custom_text?: string | null,
    isLoadingStudyCorrelations: boolean,
    questionSelected: (q: QuestionType) => void,
    actualCorrelations: any[] | null,
    handleShowStudyDetails: (index: number, corrItem: any, correlatedSQ: QuestionType | undefined) => void,
    filterCorrelationHistory: (item: any) => void,
};

const PredictCorrelationComponent = (props: Props) => {
    const {
              predictCorrelationsData,
              baseQuestion,
              custom_text,
              handleShowStudyDetails,
              questionSelected,
              isLoadingStudyCorrelations,
              filterCorrelationHistory
          } = {...props};

    return (
        <div className="pt-5 pb-5">
            {predictCorrelationsData && predictCorrelationsData.filter(
                (item: PredictCorrelationEntry) => filterCorrelationHistory(item)).length > 0 && (
                 <div className="correlationHistoryWrapper container pt-5">
                     <div className="row">
                         <div className="col">

                             <table>
                                 <thead>
                                 <tr>
                                     <td></td>
                                     <td colSpan={2}
                                         className={"lead text-start  table-heading"}>Predicted<br/>correlations
                                     </td>
                                     <td className={"lead  text-start  table-heading ps-3"}>{predictCorrelationsData.filter(
                                         item => (
                                             item.actualCorrelations !== undefined && item.actualCorrelations.length > 0
                                         )).length > 0 ? "Study" : ''}<br/>{predictCorrelationsData.filter(item => (
                                         item.actualCorrelations !== undefined && item.actualCorrelations.length > 0
                                     )).length > 0 ? "correlations" : ''}
                                     </td>
                                 </tr>
                                 </thead>
                                 <tbody>
                                 {predictCorrelationsData.filter(
                                     (item: PredictCorrelationEntry) => filterCorrelationHistory(item)).map(
                                     (item: PredictCorrelationEntry, index: number) => {
                                         const filteredActualCorrelations = predictCorrelationsData
                                             .filter((item: PredictCorrelationEntry) => filterCorrelationHistory(item))
                                             .flatMap((item: PredictCorrelationEntry) => item.actualCorrelations
                                                                                         ? item.actualCorrelations
                                                                                         : [undefined]);
                                         return (
                                             <ResultRow
                                                 key={`corrHistoryItems${index}`}
                                                 q={{
                                                     universal_q_id: 'dummy',
                                                     canonical_text: item.text,
                                                     question_type : '',
                                                     org_id        : baseQuestion ? baseQuestion.org_id : 'main',
                                                     score         : {
                                                         correlation       : item.correlation,
                                                         is_surprising     : item.is_surprising,
                                                         what_is_surprising: item.what_is_surprising
                                                     }
                                                 }}
                                                 index={index}
                                                 questionSelected={questionSelected}
                                                 isLoadingStudyCorrelations={isLoadingStudyCorrelations}
                                                 actualCorrelations={filteredActualCorrelations}
                                                 debugMode={false}
                                                 statement_searched={baseQuestion ? baseQuestion.canonical_text
                                                                                  : custom_text}
                                                 handleShowStudyDetails={handleShowStudyDetails}
                                             />
                                         );
                                     })}
                                 </tbody>
                             </table>
                         </div>
                     </div>
                 </div>
             )}
        </div>
    );
}
export default PredictCorrelationComponent;
