import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState,} from "react";
import {useSearchParams}                                                                from "react-router-dom";

const UI_STRINGS = require('../../common/ui-strings.json');

// Define the shape of the context
interface LocationContextProps {
    metaStatement: string | null;
    updateMetaStatement: (statement: string | null) => void;
    locationKey: string | null;
    updateLocationProvider: (statement: string | null, forceLocationKey?: string | null) => void;
}

// Default values for the context
const defaultLocationContext: LocationContextProps = {
    metaStatement         : null,
    updateMetaStatement   : () => {}, // Provide valid default function
    locationKey           : null,
    updateLocationProvider: () => {}, // Also provide a valid default function
};

// Create the LocationContext
const LocationContext = createContext<LocationContextProps>(defaultLocationContext);

interface LocationProviderProps {
    children: ReactNode;
}

// `LocationProvider` implementation
export const LocationProvider: React.FC<LocationProviderProps> = ({children}) => {
    const [locationKey, setLocationKey] = useState<string | null>(null);
    const [metaStatement, setMetaStatement] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    function getQueryParamSearchStr() {
        let queryParamSearchStrString = searchParams.get("search") || '';
        let queryParamSearchStr = decodeURIComponent(queryParamSearchStrString);
        return queryParamSearchStr;
    }

    const updateLocationProvider = (forceLocationKey?: string | null) => {
        if (forceLocationKey) {
            setLocationKey(forceLocationKey);
            return;
        }
        const locationSecondLevel = locationKey || window.location.pathname.split('/')[1];

        const queryParamSearchStr = getQueryParamSearchStr();
        if (queryParamSearchStr && queryParamSearchStr.length > 0) {
            setLocationKey('searchResults');
        }
        else if (locationSecondLevel && locationSecondLevel === 'questions') {
            setLocationKey('questionData');
        }
        else if (locationSecondLevel && locationSecondLevel !== 'question') {
            setLocationKey(locationSecondLevel);
        }
        else {
            setLocationKey('default')
        }
    };


    const updateMetaStatement = (stmt?: string | null) => {
        if (stmt && stmt.length > 0) {
            setMetaStatement(stmt);
        }
        else {
            const queryParamSearchStr = getQueryParamSearchStr();
            if (queryParamSearchStr && queryParamSearchStr.length > 0) {
                setMetaStatement(queryParamSearchStr);
            }
            else if (!queryParamSearchStr) {
                const locationSecondLevel = locationKey || window.location.pathname.split('/')[1];
                if (locationSecondLevel && locationSecondLevel === 'questions') {

                    const queryParamUTText = searchParams.get('text') || '';
                    const queryParamUTTextStr = decodeURIComponent(queryParamUTText);
                    if (queryParamUTTextStr && queryParamUTTextStr.length > 0) {
                        setMetaStatement(queryParamUTTextStr);
                    }
                }
            }
        }
    }

    useEffect(() => {
        const search = searchParams.get('search');
        setLocationKey(null);
    }, [window.location.pathname, searchParams])

    useEffect(() => {
        if (locationKey === null) updateLocationProvider();
        if (locationKey !== null) updateMetaStatement();

    }, [locationKey])

    return (
        <LocationContext.Provider
            value={{
                locationKey,
                updateLocationProvider,
                metaStatement,
                updateMetaStatement,
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

// Custom hook to consume the `LocationContext`
export const useLocationContext = (): LocationContextProps => {
    return useContext(LocationContext);
};