// MetaTags.tsx
import React, {useEffect, useState} from 'react';
import {Helmet}                     from 'react-helmet';
import useGA                        from "../../common/useGA4";
import {useLocationContext}         from "../../context/LocationProvider/LocationProvider";

interface MetaTagsProps {
    description?: string;
    shareUrl?: string;
}

const UI_STRINGS = require('../../common/ui-strings.json')

const MetaTags: React.FC<MetaTagsProps> = ({description, shareUrl}) => {

    const {sendGAPageView} = useGA();
    const {metaStatement, locationKey} = useLocationContext();
    const [docTitle, setDocTitle] = useState<string>('PersonalityMap');

    useEffect(() => {
        let newTitle = UI_STRINGS.metaTags.titles.default;

        if (locationKey && UI_STRINGS.metaTags.titles[locationKey]) {
            newTitle = UI_STRINGS.metaTags.titles[locationKey];
        }

        if (metaStatement) {
            newTitle = `${newTitle} | ${metaStatement}`;
        }

        if (newTitle !== docTitle) {
            setDocTitle(newTitle);
        }
    }, [locationKey, metaStatement]);

    useEffect(() => {
        let page = window.location.pathname.split('/')[1];
        sendGAPageView(page, docTitle);
    }, [docTitle])

    return (
        <Helmet>
            <title>{docTitle}</title>
            <meta property="og:title"
                  content={docTitle}/>
            {description && (
                <>
                    <meta property="og:description" content={description}/>
                </>
            )}
            {shareUrl && (
                <>
                    <meta property="og:url" content={shareUrl}/>
                </>
            )}
        </Helmet>
    );
};

export default MetaTags;