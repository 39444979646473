import React, {useEffect, useState} from "react";
import UiConfig                     from "../../common/ui-config";
import StudyQuestionType            from "../../types/study-question.type";
import QuestionStatisticsType       from "../../types/question-statistics.type";
import Utils                        from "../../common/utils";
import useWindowDimensions          from "../../common/useWindowDimentions";
import QuestionType                 from "../../types/question.type";


type Props = {
    qStatistics: QuestionStatisticsType | null,
    qStudyQuestions: StudyQuestionType[] | null,
    dataType: string,
    handleShowStudyDetails: (index: number, correlationItem: any, correlatedSQ: QuestionType | undefined) => void,
};

const StatsTable = (props: Props) => {
    const handleShowStudyDetails = props.handleShowStudyDetails;
    const [qStudyQuestions, setQStudyQuestions] = useState<StudyQuestionType[] | null>(null);
    const [qPrediction, setQPrediction] = useState<StudyQuestionType | null>(null);

    function calcMinMaxX() {
        // find min ane max on the x-axis
        const qStatistics = props.qStatistics;
        let minX = 0;
        let maxX = 0;
        if (qStatistics) {
            minX = Number(qStatistics.min);
            maxX = Number(qStatistics.max);
        }
        if (props.dataType.length > 0 && props.dataType === 'non-negative number') {
            let qStudyLocal: StudyQuestionType[] = [];
            if (qStudyQuestions && qStudyQuestions.length > 0) {
                qStudyLocal = qStudyQuestions;
            }
            else if (props.qStudyQuestions) {
                qStudyLocal = props.qStudyQuestions;
            }
            minX = Math.min(...qStudyLocal.map((sq) => Number(sq.min_value)));
            maxX = Math.max(...qStudyLocal.map((sq) => Number(sq.max_value)));

        }
        let n = Utils.decimalsNum(minX, maxX);
        return [Number(minX.toFixed(n)), Number(maxX.toFixed(n))];
    }

    const transformDataForNumberType = (sq: StudyQuestionType) => {
        let sqTransform = sq;
        if (props.dataType === 'non-negative number' && sq.std_deviation !== undefined && sq.mean !== undefined
            && sq.max_value !== undefined && sq.min_value !== undefined)
        {
            let sq_mean = Number(sq.mean);
            let sq_min = Number(sq.min_value);
            let sq_max = Number(sq.max_value);
            let sq_stddev = Number(sq.std_deviation);
            sqTransform.mean = sq_mean * (
                sq_max - sq_min
            ) + sq_min;
            sqTransform.std_deviation = sq_stddev * (
                sq_max - sq_min
            );
        }
        return sqTransform;
    }


    function numberFormat(num: number) {
        let n = Utils.numDigits(maxX - minX);
        let numDecimal = 1;
        if (n > 2) {
            numDecimal = 0;
        }
        else if (maxX <= 1) {
            numDecimal = 2;
        }

        return num.toFixed(numDecimal);
    }

    const checkStudyConditions = (sq: StudyQuestionType) => {
        if (props.dataType === 'non-negative number') {
            return sq.data_type === 'non-negative number';
        }
        else {
            return true;
        }
    }
    let [minX, maxX] = calcMinMaxX();

    const {height, width} = useWindowDimensions();
    const {STUDIES_CORR_NUM, STUDIES_NUM} = Utils.getStudyNumbers(width);

    useEffect(() => {
        if (props.qStatistics && !qPrediction) {
            let sq = {
                id           : '',
                raw_text     : '',
                study_id     : '',
                org_id       : '',
                std_deviation: Number(props.qStatistics?.std_deviation),
                mean         : Number(props.qStatistics?.mean),
                min_value    : minX,
                max_value    : maxX,
                data_type    : props.dataType,
            };

            const prediction = transformDataForNumberType(sq);
            setQPrediction(prediction);

        }

        if (props.qStudyQuestions && !qStudyQuestions) {
            const qStudyQuestionsFiltered = props.qStudyQuestions.filter((sq) => checkStudyConditions(sq)).slice(
                0, STUDIES_NUM);
            const qStudyQuestionsRealData = qStudyQuestionsFiltered.map((sq) => transformDataForNumberType(sq));
            setQStudyQuestions(qStudyQuestionsRealData);

        }
        return () => {
            setQStudyQuestions(null);
            setQPrediction(null);
        }
    }, [])


    return (
        <div>
            {(
             qStudyQuestions && qStudyQuestions.length > 0
             ) && (
                 <div className="statsTableWrapper">
                     <table
                         className="table align-middle table-bordeless table-sm mt-3">
                         <thead className="table-borderless">
                         <tr className="">
                             <th scope="col"
                                 className="border-0 p-3 align-top text-uppercase"
                             >
                             </th>
                             <th scope="col"
                                 className="  p-3 align-self-center align-top text-uppercase"
                                 style={{
                                     // textDecoration: "underline #ff7700 2px",
                                     // border: "1px solid #ff7700",
                                     backgroundColor: '#ff770020',
                                 }}>Predicted
                             </th>
                             {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                 <th scope="col"
                                     className="p-3 align-top text-uppercase"
                                     key={index} style={{
                                     backgroundColor: UiConfig.backgrounds[index],
                                 }}>
                                     <div className="hoverPointer noWrap"
                                          onClick={() => handleShowStudyDetails(
                                              index, null, undefined)}>Study&nbsp;{index + 1}<sup
                                         className="text-success-emphasis ps-1 lead"><i
                                         className="bi bi-info-circle"></i></sup>
                                     </div>
                                     {/*<div>Study&nbsp;{index + 1}*/}
                                     {/*</div>*/}
                                 </th>
                             ))}
                         </tr>
                         </thead>
                         <tbody>
                         <tr className="">
                             <td className="p-3">Mean</td>
                             <td className="p-3 text-center">{(
                                                                  qPrediction && qPrediction.mean !== undefined
                                                              ) ? numberFormat(Number(qPrediction.mean)) : "..."}</td>
                             {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                 <td className="p-3 text-center"
                                     key={index}>{numberFormat(Number(sq.mean))}</td>
                             ))}
                         </tr>
                         <tr className="">
                             <td className="p-3 pb-1 pt-1">Standard deviation</td>
                             <td className="p-3 text-center">{(
                                                                  qPrediction && qPrediction.std_deviation !== undefined
                                                              ) ? numberFormat(Number(qPrediction.std_deviation))
                                                                : "..."}</td>
                             {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                 <td className="p-3 text-center"
                                     key={index}>{sq.std_deviation ? numberFormat(Number(sq.std_deviation))
                                                                   : "..."}</td>
                             ))}
                         </tr>
                         {props.dataType === 'non-negative number' && (
                             <tr>
                                 <td className="p-3 pb-1 pt-1">Min</td>
                                 <td className="p-3"></td>
                                 {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                     <td className="p-3 text-center"
                                         key={index}>{(
                                                          sq.min_value !== undefined
                                                      ) ? numberFormat(Number(sq.min_value)) : ""}</td>
                                 ))}
                             </tr>
                         )}
                         {props.dataType === 'non-negative number' && (
                             <tr>
                                 <td className="p-3 pb-1 pt-1">Max</td>
                                 <td className="p-3"></td>
                                 {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                     <td className="p-3 text-center"
                                         key={index}>{(
                                                          sq.max_value !== undefined
                                                      ) ? numberFormat(Number(sq.max_value)) : ""}</td>
                                 ))}
                             </tr>
                         )}
                         <tr className="">
                             <td className="p-3 pb-1 pt-1">Responses (n)</td>
                             <td className="p-3"></td>
                             {qStudyQuestions.map((sq: StudyQuestionType, index: number) => (
                                 <td className="p-3 text-center"
                                     key={index}>{sq.count ? Utils.formatBigNum(sq.count) : ""}</td>
                             ))}
                         </tr>
                         </tbody>


                     </table>
                 </div>


             )}
            {qPrediction !== null && (
                (
                    qStudyQuestions && qStudyQuestions.length === 0
                ) || !qStudyQuestions
            ) && (
                 <table
                     className="table align-middle table-bordereless table-sm table-responsive">
                     <thead className="table-borderless">
                     <tr className="border-0">
                         <th scope="col"
                             className="border-0 p-3 align-top text-uppercase"></th>
                         <th scope="col"
                             className="border-0 p-3 text-center align-top text-uppercase">Predicted
                         </th>

                     </tr>
                     </thead>

                     <tbody>
                     <tr className="">
                         <td className="p-3">Mean</td>
                         <td className="p-3 text-center">{(
                                                              qPrediction && qPrediction.min_value !== undefined
                                                              && qPrediction.max_value !== undefined && qPrediction.mean
                                                              !== undefined
                                                          ) ? numberFormat(qPrediction.mean) : ""}</td>
                     </tr>
                     <tr className="">
                         <td className="p-3 pb-1 pt-1">Standard deviation</td>
                         <td className="p-3 text-center">{qPrediction ? qPrediction?.std_deviation?.toFixed(2)
                                                                      : "..."}</td>
                     </tr>
                     </tbody>
                 </table>

             )}


        </div>


    );
}
export default StatsTable;
