import QuestionType                 from "../../types/question.type";
import {LoadingSimple, Surprise}    from "../UIElements";
import React, {useEffect, useState} from "react";
import Utils                        from "../../common/utils";
import UiConfig                     from "../../common/ui-config";
import {Button}                     from "react-bootstrap";
import CopyToClipboardButton        from "../shareComponent/CopyToClipboardButton.component";
import useWindowDimensions          from "../../common/useWindowDimentions";
import {useUser}                    from "../../context/UserProvider/UserProvider";
import {useQuery}                   from "react-query";
import QuestionDataService          from "../../services/question.service";

const UI_STRINGS = require('../../common/ui-strings.json')

type Props = {
    q: QuestionType,
    index: number,
    questionSelected: (q: QuestionType) => void,
    isLoadingStudyCorrelations: boolean,
    actualCorrelations: any,
    debugMode: boolean,
    accessLevel?: number | undefined,
    statement_searched?: string | null,
    handleShowStudyDetails: (index: any, item: any, q: QuestionType | undefined) => void
};


const ResultRow = (props: Props) => {
    const {
              q,
              index,
              questionSelected,
              isLoadingStudyCorrelations,
              actualCorrelations,
              debugMode,
              statement_searched,
              handleShowStudyDetails
          } = {...props}
    const [qToLeadOnClick, setQToLeadOnClick] = useState<QuestionType | null>(null);
    const {requireAuth, accountParams} = useUser();
    const {height, width} = useWindowDimensions();
    const {STUDIES_CORR_NUM, STUDIES_NUM} = Utils.getStudyNumbers(width);

    const accessLevel = props.accessLevel ?? 2;
    const [showCopyButton, setShowCopyButton] = useState(false);
    const [correlationsList, setCorrelationsList] = useState("");
    const [copyText, setCopyText] = useState("");

    useEffect(() => {
        if (!isLoadingStudyCorrelations && actualCorrelations && actualCorrelations[index]
            && actualCorrelations[index].text === q.canonical_text && actualCorrelations[index].corr_data)
        {
            const correlations = actualCorrelations[index].corr_data
                                                          .slice(0, STUDIES_CORR_NUM)
                                                          .map((item: any) => formatCorrelation(item.correlation))
                                                          .join(", ") || "";
            setCorrelationsList(correlations);
        }
    }, [isLoadingStudyCorrelations, actualCorrelations, q.canonical_text, index, STUDIES_CORR_NUM]);


    useEffect(() => {
        const url_to_predict_correlation_page_for_main_result = window.location.href;
        const search_result = q.canonical_text;
        const correlation = q.score?.correlation ? formatCorrelation(q.score.correlation) : '';

        setCopyText(
            `PersonalityMap.io correlation between responses to the statements "${statement_searched}" and "${search_result}"

Predicted: ${correlation}` + (
                                                                                                                                                           correlationsList ? `\nFrom studies: ${correlationsList}` : ''
                                                                                                                                                       ) + `\n\n${url_to_predict_correlation_page_for_main_result}`);
    }, [statement_searched, q.canonical_text, q.score?.correlation, correlationsList]);


    function formatCorrelation(n: number) {
        return Number(n).toFixed(2);
    }

    function getHiddenText(q_text: string) {
        if (accessLevel !== undefined && accessLevel < 2 && index >= UiConfig.trialResultsNum + 3) {
            let result = '';
            const characters = ' abcdefghijklmnopqrstuvwxyz  ';
            const charactersLength = q_text.length;
            let counter = 0;
            while (counter < charactersLength) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return Utils.capitalizeFirstLetter(result.trim());
        }
        else {
            return q_text;
        }
    }

    function getNumberOfDigits(value: number): number {
        return Math.abs(value).toString().replace('.', '').length;
    }

    function getHiddenNumbers(corr_value: string | undefined, hideAll: boolean = false) {
        if (!corr_value) return '';
        if (accessLevel !== undefined && accessLevel < 2 && (
            (
                index >= UiConfig.trialResultsNum + 3
            ) || hideAll
        ))
        {
            let result = '';
            const characters = '9998881234567890';
            const charactersLength = 2;
            const numSign = corr_value.charAt(0) === '-' ? -1 : 1;
            let counter = 0;
            while (counter < charactersLength) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return numSign === -1 ? '-0.' + result.trim() : '0.' + result.trim();
        }
        else {
            return corr_value;
        }
    }

    function getHiddenStyle() {
        let m = UiConfig.trialResultsNum;
        if (accessLevel === 2) {
            return "";
        }
        else {
            if (index > m) {
                return "blurry-text-max";
            }
        }
    }

    function getHiddenStyleSurprise() {
        let m = UiConfig.trialResultsNum;
        if (accessLevel === 2) {
            return "";
        }
        else {
            if (index > m) {
                return "blurry-surprise";
            }
        }
    }

    function getScoreStyle(score: number | undefined) {
        let style: string = '';
        if (score) score < 0 ? style = 'red' : style = 'regular';
        return style;
    }

    function getHiddenStyleScore(score: number | undefined, hideAll = false) {
        let m = hideAll ? -1 : UiConfig.trialResultsNum;
        if (accessLevel === 2) {
            return "";
        }
        else {
            if (index > m) {
                let style: string = '';
                if (score) score < 0 ? style = 'blurry-text-max-red' : style = 'blurry-text-max';
                return style;
            }
        }
    }

    function rowClicked(q: QuestionType) {
        if (accessLevel === 2) {
            if (q.universal_q_id !== 'dummy') {
                setQToLeadOnClick(q);
            }
            else {
                getQuestionByText();
            }

        }
        else if (requireAuth) {
            requireAuth();
        }
    }

    const {refetch: getQuestionByText} = useQuery<QuestionType, Error>(
        "query-single-another-question",
        async () => {
            return await QuestionDataService.getQuestionByText('main', q.canonical_text, accountParams);
        }, {
            enabled  : false,
            retry    : 2,
            onSuccess: (res) => handleQToLeadOnClickResponse(res),
            onError  : (err: any) => {
                console.log(err)
            },
        }
    )

    function handleQToLeadOnClickResponse(res: any) {
        if (res) {
            let newQ = {...res, "score": q.score};
            setQToLeadOnClick(newQ);
        }
    }

    useEffect(() => {
        if (qToLeadOnClick) {
            questionSelected(qToLeadOnClick);
        }
    }, [qToLeadOnClick]);


    return (
        <tr onMouseEnter={() => accessLevel == 2 ? setShowCopyButton(true) : false}
            onMouseLeave={() => setShowCopyButton(false)} onClick={() => rowClicked(q)} className={
            (
                (
                    index <= UiConfig.trialResultsNum
                ) || (
                    accessLevel === undefined
                ) || (
                    accessLevel === 2
                )
            ) ? "questionResult " : ""
        } key={`corrFor${q.canonical_text}_${index}`}>
            <td className={"surprisingCell" + " " + getHiddenStyleSurprise()}>{q.score && q.score.is_surprising
                                                                               && q.score.what_is_surprising && (
                                                                                   <Surprise
                                                                                       what_is_surprising={UI_STRINGS.surprising_correlations[q.score.what_is_surprising]}/>
                                                                               )}
            </td>
            <td className={"scoreSpan " + getScoreStyle(q.score?.correlation) + " " + getHiddenStyleScore(
                q.score?.correlation)}>{getHiddenNumbers(q.score?.correlation.toFixed(2))}</td>
            <td className="position-relative">
                <div
                    className={" resultQText align-top " + " " + getHiddenStyle()}>{getHiddenText(q.canonical_text)}
                </div>
                <div>
                    {props.accessLevel !== undefined && props.accessLevel !== 2 && index == UiConfig.trialResultsNum + 2
                     && (
                         <Button className="signUpFromResults" size={"lg"} variant={"outline-warning"}
                                 onClick={requireAuth}>Sign up for free
                             to see all&nbsp;results!</Button>
                     )}
                </div>
            </td>
            <td className={" actualCorrCell text-end"}>
                {!isLoadingStudyCorrelations && actualCorrelations && actualCorrelations[index]
                 && actualCorrelations[index].text === q.canonical_text && actualCorrelations[index].corr_data.slice(
                        0, STUDIES_CORR_NUM).map((item: any, index2: number) => {

                        return (
                            <div
                                className={(
                                               accessLevel === 2 ? "studyCorrValue " : ""
                                           ) + " ps-3 pe-3 d-inline" + " " + getScoreStyle(item.correlation) + " "
                                           + getHiddenStyleScore(item.correlation, true)}
                                key={index2 + index + 'actualCorrFor' + q.canonical_text}
                                onClick={(e) => {
                                    if (accessLevel == 2) {
                                        e.stopPropagation();
                                        handleShowStudyDetails(undefined, item, q);
                                    }
                                }}>{getHiddenNumbers(formatCorrelation(item.correlation))}</div>
                        )
                    })}
                {isLoadingStudyCorrelations && (
                    <div className={"ps-3"}><LoadingSimple/></div>
                )}
            </td>
            <td style={{
                minWidth     : '3rem',
                minHeight    : '3rem',
                position     : 'relative',
                padding      : 0,
                margin       : 0,
                verticalAlign: 'middle', border: 'none',
            }}
            >
                <div className='copyCell' style={{
                    position      : 'absolute',
                    top           : '50%',
                    left          : '50%',
                    transform     : 'translate(-50%, -50%)',
                    display       : 'flex',
                    alignItems    : 'center',
                    justifyContent: 'center',
                    width         : '100%',
                    height        : '100%',
                    boxSizing     : 'border-box',
                    padding       : 0,
                    margin        : 0
                }}>
                    {showCopyButton && (
                        <CopyToClipboardButton text={copyText}/>
                    )}
                </div>
            </td>
            {
                debugMode && (
                              <td className='fst-italic align-top'>
                                  In&nbsp;training:&nbsp;<span
                                  className='fw-bolder fst-italic ps-2 pe-5'>{q.in_training ? "1" : "0"}</span>
                              </td>
                          )}
            {debugMode && (
                <td className='d-flex flex-wrap d-flex align-items-start'>
                    {q.studies?.map((s, index3) => (
                        <span
                            className="pe-3 fst-italic debugStudy"
                            key={index3}>{s.study_name}</span>
                    ))}
                </td>
            )}
        </tr>
    );
}

export default ResultRow;