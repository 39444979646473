import {useQuery, useQueryClient}                 from 'react-query';
import QuestionDataService                        from '../services/question.service';
import {useEffect, useState, useCallback, useRef} from "react";
import {useCorrelation}                           from "./useCorrelation";
import {useUser}                                  from "../context/UserProvider/UserProvider";
import axios, {AxiosError}                        from "axios";

export function useConnections(
    org_id: string,
    id: string,
    connectionsStr: any,
    baseQuestion: any,
    custom_text: any,
    handleConnectionsData: Function,
    handleError: Function
)
{
    const {accountParams} = useUser();
    const [stage, setStage] = useState<string>("Getting ready to send request...");
    const [localConnectionsData, setLocalConnectionsData] = useState<any[]>([]);
    const [correlation, setCorrelation] = useState<number | undefined>(undefined);
    const [fetchReducedConnections, setFetchReducedConnections] = useState(false);
    const queryClient = useQueryClient();
    const timeout = 29000;

    const abortControllerRef = useRef<AbortController | null>(null); // Reference to reuse the controller
    const handleCorrelation = useCallback((res: any) => {
        if (res) {
            setCorrelation(res.correlation);
        }
        else {
            console.log('No correlation data found');
        }
    }, []);

    useEffect(() => {
    }, [stage, localConnectionsData, correlation, fetchReducedConnections]);

    const {isLoadingCorrelation, requestCorrelation} = useCorrelation(
        org_id, id, connectionsStr, baseQuestion, custom_text, handleCorrelation, handleError
    );

    const fetchConnections = useCallback(async () => {
        setStage("Fetching connections...");
        let _text = baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text;

        // Cancel any pre-existing request
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new controller for this request
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        try {
            const data = await QuestionDataService.getConnections(org_id, _text, connectionsStr, accountParams, signal);
            return data;
        }
        catch (error: AxiosError | unknown) {
            if (signal.aborted) {
                console.warn("Fetch connections request aborted");
                return;
            }
            else {
                console.error("Fetch connections failed: ", error);
                throw error; // Ensure React Query receives it
            }
        }
        finally {
            abortControllerRef.current = null; // Reset controller on completion
        }
    }, [org_id, baseQuestion, custom_text, connectionsStr, handleError]);

    function handleNullData() {
        setStage("Done, no connections found!");
        const dummyData = {
            'statements' : [
                baseQuestion && !custom_text
                ? baseQuestion.canonical_text
                : custom_text,
                connectionsStr
            ],
            'connections': [],
            'correlation': 100,
        };
        handleConnectionsData(dummyData);
    }

    const {
              isLoading: isLoadingConnections,
              data     : connectionsData,
              error    : connectionsError,
          } = useQuery(["query-fetch-connections", org_id, connectionsStr], fetchConnections, {
        enabled   : !!connectionsStr && !!correlation,
        retry     : (failureCount, error: any) => {
            if (error?.code === "ERR_NETWORK" || error?.message.includes("timeout")) {
                return failureCount < 4; // Retry network errors up to 4 times
            }
            return false; // Do not retry other errors
        },
        retryDelay: (attempt) => Math.min(2 ** attempt * 1000, 30000), // e.g., exponential backoff with a cap
        onSuccess : async (data) => {
            if (data && Array.isArray(data)) {
                setLocalConnectionsData(data);
                if (data.length === 0) {
                    handleNullData();
                }
                else {
                    setFetchReducedConnections(true);
                }
            }
            else if (data == null) {
                handleNullData();
            }
            else {
                handleError(new Error("Unexpected data structure"));
            }
        },
        onError   : (error: unknown) => {
            if (axios.isAxiosError(error) && error.response?.data) {
                console.error("Request completed after timeout, but response:", error.response.data);
                // Optionally set local state if needed
            }
            else {
                console.error("Unhandled error:", error);
            }
            handleError(error, 'connections');
        }

    });
    useEffect(() => {
        if (connectionsError) {
            if (connectionsError.response?.status === 504) {
                console.warn("504 Gateway Timeout: Falling to default state");
                setLocalConnectionsData([]);
            }
            handleError(connectionsError, 'connections');
        }
    }, [connectionsError, handleError]);


    const fetchReducedConnectionsFunction = useCallback(async () => {
        if (localConnectionsData.length === 0) {
            console.warn("No connection data available to reduce. Using fallback state.");
            return []; // Gracefully fallback to empty return
        }
        setStage("Connections are found!");
        let _text = baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text;
        const chunks = [];
        for (let i = 0; i < localConnectionsData.length; i += 10) {
            chunks.push(localConnectionsData.slice(i, i + 10));
        }
        let reducedConnectionsChunks: any[] = [];
        setStage("Filtering and sorting connections...");

        for (let index = 0; index < chunks.length; index++) {
            const chunk = chunks[index];
            if (chunk && Array.isArray(chunk)) {
                try {
                    const reducedConnectionsForChunk = await Promise.race([
                                                                              QuestionDataService.reduceConnections(
                                                                                  org_id, [_text, connectionsStr],
                                                                                  chunk
                                                                              ),
                                                                              new Promise((_, reject) => setTimeout(
                                                                                  () => reject(new Error(
                                                                                      'Request timed out')),
                                                                                  timeout
                                                                              )),
                                                                          ]);
                    if (reducedConnectionsForChunk && Array.isArray(reducedConnectionsForChunk)) {
                        reducedConnectionsChunks.push(...reducedConnectionsForChunk);
                    }
                }
                catch (error) {
                    handleError(new Error(`Timeout on chunk ${index + 1}`));
                }
            }
            setStage(`Filtering and sorting connections (${index + 1} / ${chunks.length})...`);
        }

        reducedConnectionsChunks.sort((a, b) => {
            const factorA = Array.isArray(a?.linear_regression) && a.linear_regression.length >= 2
                            ? Math.sqrt(Math.abs(a.linear_regression[0]) * Math.abs(a.linear_regression[1]))
                            : 0; // Default to 0 or some other safe value
            const factorB = Array.isArray(b?.linear_regression) && b.linear_regression.length >= 2
                            ? Math.sqrt(Math.abs(b.linear_regression[0]) * Math.abs(b.linear_regression[1]))
                            : 0; // Default to 0 or some other safe value
            return factorB - factorA; // for descending order
        });
        return reducedConnectionsChunks.slice(0, 20);


    }, [org_id, baseQuestion, custom_text, connectionsStr, localConnectionsData]);

    const {
              isLoading: isLoadingReducedConnections,
              data     : reducedConnectionsData,
              error    : reducedConnectionsError,
          } = useQuery(
        ["query-fetch-reduced-connections", org_id, connectionsStr, localConnectionsData.length],
        fetchReducedConnectionsFunction,
        {
            enabled: fetchReducedConnections && localConnectionsData.length > 0,
            retry  : 4,
        }
    );

    useEffect(() => {

    }, [connectionsStr])

    useEffect(() => {
        if (connectionsStr && correlation === undefined) {
            requestCorrelation();
        }
        else if (connectionsStr && localConnectionsData.length > 0 && correlation !== undefined) {
            queryClient.invalidateQueries(["query-fetch-connections", "query-fetch-reduced-connections"]);
            setStage("searching for connections");
            setLocalConnectionsData([]);
            setCorrelation(undefined);
            setFetchReducedConnections(false);
        }
        else if (!connectionsStr) {
            setCorrelation(undefined)
        }


    }, [connectionsStr, queryClient]);

    useEffect(() => {
        let inputBlock = document.getElementById('connectionsErrorBlock');
        if (inputBlock && isLoadingConnections) inputBlock.innerHTML = '';
    }, [isLoadingConnections]);

    useEffect(() => {
        if (connectionsError) handleError(connectionsError, 'connections');
        if (reducedConnectionsError) handleError(reducedConnectionsError, 'connections');
        if (
            !isLoadingConnections &&
            !isLoadingReducedConnections &&
            !isLoadingCorrelation &&
            connectionsData !== undefined &&
            reducedConnectionsData !== undefined &&
            correlation !== undefined
        )
        {
            setStage("Done!");
            handleConnectionsData({
                                      'statements' : [
                                          baseQuestion && !custom_text ? baseQuestion.canonical_text : custom_text,
                                          connectionsStr
                                      ],
                                      'correlation': correlation,
                                      'connections': reducedConnectionsData
                                  });
        }
    }, [
                  connectionsData,
                  reducedConnectionsData,
                  correlation,
                  handleConnectionsData,
                  handleError
              ]);

    return {
        isLoadingConnections: isLoadingConnections || isLoadingReducedConnections || isLoadingCorrelation,
        fetchConnections,
        connectionsStage    : stage
    };
}