function boldStrInText(str: string, sText: string, id_part: string) {
    if (str !== null) {
        let regExpStr = new RegExp(escapeRegExp(str), 'ig'); // 'i' flag for case-insensitive, 'g' for global search
        let highlightedText = sText.replace(regExpStr, "<span class='fw-bold'>$&</span>");
        return (
            <div
                id={"suggest" + id_part}
                className="d-inline"
                title={sText}
                dangerouslySetInnerHTML={{__html: highlightedText}}
            />
        );
    }
    else {
        return (
            <div
                id={"suggest" + id_part}
                className="d-inline"
                title={sText}
                dangerouslySetInnerHTML={{__html: sText}}
            />
        );
    }
}

const formatResponse = (res: any) => {
    return JSON.stringify(res, null, 2);
};

function getTextFromSuggestion(e: any) {
    let el = e.target;
    if (el.tagName === "SPAN") {
        let parent = el.parentElement;
        if (parent.title && /suggest/i.test(parent.id)) {
            return parent.title
        }
        else {
            return el.innerText;
        }
    }
    else if (el.title) {
        return el.title
    }
    else {
        return el.innerText;
    }
}

function getChildrenCount(id: string) {
    let el = document.getElementById(id);
    if (el) {
        return el.childElementCount
    }
    else {
        return 0
    }
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function numDigits(x: number) {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
}

function newlinesInJson(_str: string) {
    const arr = _str.split('\n');
    let result = '';
    for (let s of arr) {
        result += s + '<br/>';
    }
    return (
        <div dangerouslySetInnerHTML={{__html: result}}/>
    )

}


function decimalsNum(minV: number, maxV: number) {
    // calc number of decimals
    let n = numDigits(maxV - minV);
    let numDecimal = 1;
    if (n > 1) {
        numDecimal = 0;
    }
    else if (maxV <= 1) {
        numDecimal = 2;
    }
    return numDecimal;

}

function formatBigNum(n: number) {
    return new Intl.NumberFormat().format(n)
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function escapeReplacement(string: string) {
    return string.replaceAll("\\", "");
}


function reportTime(startTime: number) {
    let now = new Date().getTime();
    // console.log("Time passed: ", Math.round((now - startTime) / 1000), " sec")
}

function formatCorrelation(n: number) {
    return Number(n).toFixed(2);
}

function formatEffectSize(n: number) {
    return Number(n).toFixed(1);
}

function formatR2(n: number) {
    return Number(n).toFixed(4);
}

const getStudyNumbers = (width: number) => {

    let STUDIES_CORR_NUM = 3;
    let STUDIES_NUM = 3;

    if (width < 1200) {
        STUDIES_CORR_NUM = 2;
        STUDIES_NUM = 3;
    }

    return {STUDIES_CORR_NUM, STUDIES_NUM};
};


const Utils = {
    boldStrInText,
    getTextFromSuggestion,
    getChildrenCount,
    numDigits,
    decimalsNum,
    formatBigNum,
    escapeRegExp,
    escapeReplacement,
    reportTime,
    capitalizeFirstLetter, newlinesInJson, formatCorrelation, formatR2, formatResponse, getStudyNumbers,
    formatEffectSize
}
export default Utils;
