import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY_FOR_VERIFY_EMAIL;

if (!SECRET_KEY) {
    throw new Error("SECRET_KEY is not defined. Please configure it in your environment variables.");
}

/**
 * Encrypts data using TripleDES and a secret key.
 * @param data Data to encrypt (string or serializable object)
 * @returns Encrypted string
 */
export const encryptData = (data: string | object): string => {
    const dataString = typeof data === "string" ? data : JSON.stringify(data);
    return CryptoJS.TripleDES.encrypt(dataString, SECRET_KEY!).toString();
};

/**
 * Decrypts encrypted data using TripleDES and a secret key.
 * @param ciphertext The encrypted string
 * @returns Decrypted data
 */
export const decryptData = (ciphertext: string): string => {
    const bytes = CryptoJS.TripleDES.decrypt(ciphertext, SECRET_KEY!);
    return bytes.toString(CryptoJS.enc.Utf8);
};