// todo: automate this!
import {AccountTypeParams} from "../types/userContext.type";

const colors: string[] = ['#004e6e', '#0085ba', '#009fc8', '#5dbcdf', '#8ad1e5'];
const backgrounds: string[] = ['#004e6e50', '#0085ba50', '#009fc850', '#5dbcdf50', '#8ad1e550'];
const suggestionsTimeout: number = 0;
const chartPrecision: number = 1000;
const searchExamples: string[] = [
    'Anxiety', 'I like to tidy up', 'How old are you?', 'Giant robots are cool', 'Narcissism', 'I smile a lot',
    'Introversion', 'Cats are clearly better than dogs',
]
const initialRegressionExamples: string[] = []
const includeTypes = ["likert scale", "yes or no", "any real number", "non-negative number", "any integer"];
const charLimit = 2;
const trialAccessLimit = 2;
const trialResultsNum = 4;
const cytoscapeLayoutSet = {
    'network'    : [
        'grid', 'circle', 'klay', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_layered', 'elk_mrtree'
    ],
    'connections': [
        'preset', 'fcose', 'cose-bilkent', 'cola', 'dagre', 'elk_box', 'elk_disco', 'elk_force', 'elk_mrtree',
        'elk_stress'
    ]
}

const accountTypeParams: AccountTypeParams = {
    'free' : {"include_unsafe": false, "return_categories": true},
    'trial': {"include_unsafe": true, "return_categories": true},
    'paid' : {"include_unsafe": true, "return_categories": true},
    'team' : {"include_unsafe": true, "return_categories": true},
};

const compositeScoresTexts = `
adverse childhood experiences (ACEs composite score),1
attention-deficit / hyperactivity disorder (ASRS ADHD composite score),1
avoidance behavior (composite score),1
generalized anxiety (GAD-7 composite score),1
joint hypermobility (composite score),1
negative self-talk (composite score),1
depression (PHQ-9 composite score),1
self-compassion (personality composite score),1
"number of acutely stressful life events in the past two months, such as eviction, death or job loss (composite score)",1
"number of chronically stressful ongoing life events, such as financial strain, disability, or an abusive relationship (composite score)",1
agreeableness (Big 5 personality composite score),0
closedness (Big 5 personality composite score),0
conscientiousness (Big 5 personality composite score),0
disagreeableness (Big 5 personality composite score),0
good employee (self-report composite score),1
extraversion (Big 5 personality composite score),0
honesty (personality composite score),0
introversion (Big 5 personality composite score),0
neuroticism (Big 5 personality composite score),0
openness (Big 5 personality composite score),0
emotional stability (Big 5 personality composite score),0
unconscientiousness (Big 5 personality composite score),0
anxiety (personality composite score),0
attention-seeking (personality composite score),1
authoritarianism (personality composite score),0
charisma (personality composite score),1
compassion (personality composite score),1
creativity (personality composite score),1
emotional expressiveness (personality composite score),1
fairness (personality composite score),1
psychological flexibility (personality composite score),1
gentleness (personality composite score),1
greed avoidance (personality composite score),1
humor (personality composite score),1
industriousness (personality composite score),0
intellect (personality composite score),0
introspection (personality composite score),1
irritability (personality composite score),1
orderliness (personality composite score),0
perfectionism (personality composite score),1
sincerity (personality composite score),0
sociability (personality composite score),1
trust (personality composite score),1
unconventionality (personality composite score),1
well-being (personality composite score),1
conformity (personality composite score),1
forgiveness (personality composite score),1
manipulativeness (personality composite score),1
optimism (personality composite score),1
prudence (personality composite score),1
adaptability (personality composite score),1
art appreciation (personality composite score),1
conservatism (personality composite score),1
easy goingness (personality composite score),1
unemotional (personality composite score),1
impulsivity (personality composite score),1
industry (personality composite score),0
order (personality composite score),0
self-control (personality composite score),1
sensation-seeking (personality composite score),1
Enneagram type 1 - the Reformer (personality composite score),0
Enneagram type 2 - the Helper (personality composite score),0
Enneagram type 3 - the Achiever (personality composite score),0
Enneagram type 4 - the Individualist (personality composite score),0
Enneagram type 5 - the Investigator (personality composite score),0
Enneagram type 6 - the Loyalist (personality composite score),0
Enneagram type 7 - the Enthusiast (personality composite score),0
Enneagram type 8 - the Challenger (personality composite score),0
Enneagram type 9 - the Peacemaker (personality composite score),0
extraversion (Myers-Briggs/MBTI-style personality composite score),0
introversion (Myers-Briggs/MBTI-style personality composite score),0
judging (Myers-Briggs/MBTI-style personality composite score),0
perceiving (Myers-Briggs/MBTI-style personality composite score),0
sensing (Myers-Briggs/MBTI-style personality composite score),0
intuition (Myers-Briggs/MBTI-style personality composite score),0
thinking (Myers-Briggs/MBTI-style personality composite score),0
feeling (Myers-Briggs/MBTI-style personality composite score),0
altruistic (personality composite score),1
anxious (personality composite score),0
comfortable with myself (personality composite score),1
conflict-avoidant (personality composite score),1
conformist (personality composite score),0
conversationalist (personality composite score),1
demanding (personality composite score),1
emotional (personality composite score),1
empathetic (personality composite score),0
exploratory (personality composite score),1
group-oriented (personality composite score),1
hard-working (personality composite score),1
intellectual (personality composite score),1
leader-like (personality composite score),1
logical (personality composite score),1
organized (personality composite score),1
perfectionistic (personality composite score),0
planning (personality composite score),0
pragmatic (personality composite score),1
quick-minded (personality composite score),1
rule abiding (personality composite score),1
socially energized (personality composite score),0
spontaneous (personality composite score),1
`;

function parseCompositeScores(content: string): Record<string, string> {
    const lines = content.trim().split('\n');

    const dictionary: Record<string, string> = {};
    lines.forEach(line => {
        if (line.endsWith(',1')) { // Check if the line ends with ',1'
            const keyPart = line.slice(0, line.lastIndexOf(',')).trim(); // Remove ',1' from the end
            const key = keyPart.split('(')[0].trim(); // Remove the part in parentheses (e.g., "(personality composite
                                                      // score)")
            dictionary[key] = keyPart; // Store clean key and the unmodified text (excluding ',1')
        }
    });

    return dictionary;
}

const factorsFromFile = parseCompositeScores(compositeScoresTexts);

const factors = {
    'big5'     : {
        "openness"           : "openness (Big 5 personality composite score)",
        "conscientiousness"  : "conscientiousness (Big 5 personality composite score)",
        "extraversion"       : "extraversion (Big 5 personality composite score)",
        "agreeableness"      : "agreeableness (Big 5 personality composite score)",
        "emotional stability": "emotional stability (Big 5 personality composite score)",
    },
    'general'  : {
        "age"                   : "age",
        "female"                : "female",
        "education"             : "What is your highest level of completed education?",
        "political conservatism": "On the political spectrum I am 'right' (conservative) rather than 'left' (progressive)",
        "social class"          : "Based on your background, the people you spend the most time with, and your current situation in life, to what extent would you consider yourself upper class rather than lower class?"
    },
    'subscales': factorsFromFile
}
const numberTopSubscales = 5;


const big5Strings: string[] = []

const questionTabs = [
    'statistics',
    'mostCorrelated', 'predictCorrelation',
    'predictLinearRegression', 'network', 'factors', 'connections'
]

type shareTextDetails = {
    [tabType: string]: {
        titleM: string;
        shareText: (stmt: string) => string;
    };
};
// todo: make dependent on LocationContext like MetaTags
const shareTextDetails: shareTextDetails = {
    'statistics'             : {
        titleM   : "PersonalityMap Statistics for the statement",
        shareText: (stmt: string) => `PersonalityMap Statistics for the statement: ${stmt}`
    },
    'mostCorrelated'         : {
        titleM   : "PersonalityMap Predicted correlations for the statement",
        shareText: (stmt: string) => `PersonalityMap Predicted correlations for the statement: ${stmt}`
    },
    'predictCorrelation'     : {
        titleM   : "PersonalityMap Correlations for the statement",
        shareText: (stmt: string) => `PersonalityMap Correlations for the statement: ${stmt}`
    },
    'predictLinearRegression': {
        titleM   : "PersonalityMap Predicted linear regression for the statement",
        shareText: (stmt: string) => `PersonalityMap Predicted linear regression for the statement: ${stmt}`
    },
    'network'                : {
        titleM   : 'PersonalityMap Network of connections for the statement',
        shareText: (stmt: string) => `PersonalityMap Network of connections for the statement: ${stmt}`
    },
    'connections'            : {
        titleM   : 'PersonalityMap Non-trivial connections for the statement',
        shareText: (stmt: string) => `PersonalityMap Non-trivial connections for the statement: ${stmt}`
    },
    'factors'                : {
        titleM   : 'PersonalityMap Predicted partial correlation with each of the personality traits for the statement',
        shareText: (stmt: string) => `PersonalityMap Predicted partial correlation with each of the personality traits for the statement: ${stmt}`
    },
    'default'                : {
        titleM   : 'PersonalityMap Predicted correlations for the statement',
        shareText: (stmt: string) => `PersonalityMap Predicted correlations for the statement: ${stmt}`
    },
};

const UiConfig = {
    colors,
    backgrounds,
    suggestionsTimeout,
    chartPrecision,
    searchExamples,
    includeTypes,
    charLimit,
    trialAccessLimit,
    trialResultsNum,
    initialRegressionExamples,
    big5Strings,
    questionTabs,
    shareTextDetails,
    factors, numberTopSubscales, accountTypeParams, cytoscapeLayoutSet
}

export default UiConfig;
