import React, {ReactNode} from "react";
import {UserProvider}     from "./UserProvider/UserProvider";
import {LocationProvider} from "./LocationProvider/LocationProvider";

const AppProviders: React.FC<{ children: ReactNode }> = ({children}) => {
    return (
        <UserProvider>
            <LocationProvider>
                {children}
            </LocationProvider>
        </UserProvider>
    );
};

export default AppProviders;